import React, { useEffect, useState } from 'react';
import { Table, Spin, message, Input, Modal, Button } from 'antd';
import axios from 'axios';
import moment from 'moment';
import 'antd/dist/reset.css';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import CertificateModal from './CertificateModal';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from 'react-csv';

const TestList = () => {
  const [testBookings, setTestBookings] = useState([]);
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [searchTerm, setSearchTerm] = useState(''); // Search term state
  const [uniqueIdSearchTerm, setUniqueIdSearchTerm] = useState('');
  const [confirmState, setConfirmState] = useState({});
  const { t, i18n } = useTranslation();
  const [loggedBookings, setLoggedBookings] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null); 

  const API_URL = process.env.REACT_APP_API_URL;

  const fetchTestBookings = async (date = null, search = '') => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/test/book-test`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
  
      let filteredBookings = response.data;
  
      // Only show "pass" records
      filteredBookings = filteredBookings.filter(booking => booking.pass_fail === 'pass');
  
      if (date) {
        filteredBookings = filteredBookings.filter(booking =>
          moment(booking.test_date).isSame(date, 'day')
        );
      }
  
      if (search) {
        filteredBookings = filteredBookings.filter(booking =>
          (booking.student_firstnamethai && booking.student_firstnamethai.toLowerCase().includes(search.toLowerCase())) ||
          (booking.student_lastnamethai && booking.student_lastnamethai.toLowerCase().includes(search.toLowerCase())) ||
          (booking.student_firstnameenglish && booking.student_firstnameenglish.toLowerCase().includes(search.toLowerCase())) ||
          (booking.student_lastnameenglish && booking.student_lastnameenglish.toLowerCase().includes(search.toLowerCase())) ||
          (booking.nationalid && booking.nationalid.includes(search))
        );
      }
  
      setTestBookings(filteredBookings);
      setFilteredBookings(filteredBookings);
    } catch (error) {
      console.error('Error fetching test bookings:', error);
      message.error(t('Error fetching test bookings'));
    } finally {
      setLoading(false);
    }
  };

  const fetchLoggedBookings = async () => {
    try {
      const response = await axios.get(`${API_URL}/test/test-booking-log`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setLoggedBookings(response.data); // Store the logged bookings
    } catch (error) {
      console.error('Error fetching logged bookings:', error);
      message.error('Error fetching logged bookings');
    }
  };

  useEffect(() => {
    fetchTestBookings();
    fetchLoggedBookings();
  }, []);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    fetchTestBookings(date, searchTerm); // Fetch based on selected date and search term
  };

  const handleUniqueIdSearch = async (value) => {
  setUniqueIdSearchTerm(value);
  if (!value) {
    // If search term is empty, reset to show all bookings
    setFilteredBookings(testBookings);
    return;
  }

  try {
    // First, filter logged bookings by unique ID
    const matchingLoggedBookings = loggedBookings.filter(logged => 
      logged.unique_id.toLowerCase().includes(value.toLowerCase())
    );

    // Then, filter test bookings based on the matching logged bookings
    const filteredByUniqueId = testBookings.filter(booking => 
      matchingLoggedBookings.some(logged => logged.test_booking_id === booking.id)
    );

    setFilteredBookings(filteredByUniqueId);
  } catch (error) {
    console.error('Error searching by unique ID:', error);
    message.error(t('Error searching by unique ID'));
  }
};

const handleSearch = (value) => {
  setSearchTerm(value);
  fetchTestBookings(selectedDate, value);
};

  const handleConfirmFee = (record) => {
  
    // Ensure that studentid is correctly passed from the record
    if (!record.studentid) {
      message.error('Student ID is missing');
      return;
    }
  
    let documentNo = ''; // Variable to store the document number input
  
    // Open the modal with an input for document_no
    Modal.confirm({
      title: t('Confirm Fee'),
      content: (
        <>
          <p>{t('Please enter the document number for the payment of ฿100:')}</p>
          <Input
            placeholder={t('Enter Document No')}
            onChange={(e) => documentNo = e.target.value} // Capture document number input
          />
        </>
      ),
      onOk: async () => {
        if (!documentNo) {
          message.error(t('Please enter a document number!'));
          return;
        }
  
        try {
          // Generate the unique identifier: PNtransaction_id + date + student_id + test_booking_id
          const currentDate = new Date().toISOString().slice(0, 10); // Get current date in YYYY-MM-DD format
          const uniqueId = `${documentNo}-${currentDate}-${record.studentid}-${record.id}`;
  
          // Proceed with confirming the fee
          await axios.post(`${API_URL}/test/test-booking-log`, {
            test_booking_id: record.id,
            transaction_id: documentNo,  // Use documentNo entered by the user as transaction_id
            student_id: record.studentid,  // Use studentid from the record
            fee_paid: true,
            document_issued: false,
            unique_id: uniqueId,  // Include the unique ID in the request
          }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });
  
          // Update local state to reflect confirmation
          setConfirmState((prevState) => ({
            ...prevState,
            [record.id]: { confirmed: true, issued: false },
          }));
  
          message.success(t('Fee confirmed successfully!'));
        } catch (error) {
          message.error(t('Error confirming fee.'));
        }
      },
    });
  };

  const handleIssueDocument = (record) => {
    const loggedBooking = loggedBookings.find(logged => logged.test_booking_id === record.id);
    
    if (!loggedBooking) {
      message.error(t('No logged booking found for this record.'));
      return;
    }
  
  
    setSelectedRecord({
      ...record, // Spread the existing record fields
      unique_id: loggedBooking.unique_id // Add the unique_id from the logged bookings
    });
  
    setIsModalVisible(true);    
  };

  const isBookingLogged = (test_booking_id) => {
    return loggedBookings.some(logged => logged.test_booking_id === test_booking_id);
  };
  
  const isDocumentIssued = (test_booking_id) => {
    const logged = loggedBookings.find(logged => logged.test_booking_id === test_booking_id);
    return logged && logged.document_issued;
  };

  const csvHeaders = [
    { label: t('Test Date'), key: 'test_date' },
    { label: t('schoolid'), key: 'schoolid' },
    { label: t('student'), key: 'student' },
    { label: t('nationalid'), key: 'nationalid' },
    { label: t('course'), key: 'course_name' },
    { label: t('subject'), key: 'subject' },
    { label: t('practicescore'), key: 'score' },
    { label: t('paperscore'), key: 'paperscore' },
    { label: t('Test Result'), key: 'pass_fail' }
  ];

  const csvData = testBookings.map(booking => ({
    test_date: moment(booking.test_date).format('DD-MM-YYYY'),
    schoolid: booking.schoolid,
    student: `${booking.student_firstname} ${booking.student_lastname} (${booking.student_nickname})`,
    nationalid: booking.nationalid,
    course_name: booking.course_name,
    subject: booking.subject,
    score: booking.score,
    paperscore: booking.paperscore,
    pass_fail: booking.pass_fail === 'pass' ? t('Pass') : t('Fail'),
  }));

  const columns = [
    {
      title: t('schoolid'),
      dataIndex: 'schoolid',
      key: 'schoolid',
    },
    {
      title: t('student'),
      key: 'studentNameThai',
      ellipsis: true, 
      render: (_, record) => `${record.student_firstnamethai} ${record.student_lastnamethai}` ,
    },
    {
      title: t('Student'),
      key: 'studentNameEnglish',
      ellipsis: true, 
      render: (_, record) => `${record.student_firstnameenglish} ${record.student_lastnameenglish} `,
    },
    {
      title: t('nationalid'),
      dataIndex: 'nationalid',
      key: 'nationalid',
    },
    {
      title: t('course'),
      dataIndex: 'course_name',
      key: 'course_name',
      ellipsis: true, 
    },
    {
      title: t('subject'),
      dataIndex: 'subject',
      key: 'subject',
      ellipsis: true, 
    },
    {
      title: t('Test Date'),
      dataIndex: 'test_date',
      key: 'test_date',
      ellipsis: true, 
      render: (date) => {
        if (!date) return 'N/A';
        return new Intl.DateTimeFormat(i18n.language, {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
        }).format(new Date(date));
      },
    },
    {
      title: t('practicescore'),
      dataIndex: 'score',
      key: 'score',
      render: (score) => score ? Number.parseInt(score, 10) : t('Not Set'),
    },
    
    { 
      title: t('paperscore'),
      dataIndex: 'paperscore',
      key: 'paperscore',
    },
    {
      title: t('Test Result'),
      dataIndex: 'pass_fail',
      key: 'pass_fail',
      render: (status) => {
        return status === 'pass' ? t('Pass') : 'N/A';
      },
    },
    {
      title: t('Issue Document'),
      key: 'issueDocument',
      render: (_, record) => {
        const recordState = confirmState[record.id] || { confirmed: false, issued: false };
        
        const bookingLogged = isBookingLogged(record.id);
        const documentIssued = isDocumentIssued(record.id);
    
        if (documentIssued || recordState.issued) {
          return (
            <Button disabled>
              {t('D')}
            </Button>
          );
        } else if (bookingLogged || recordState.confirmed) {
          return (
            <Button  onClick={() => handleIssueDocument(record)}>
              {t('I')}
            </Button>
          );
        } else {
          return (
            <Button  onClick={() => handleConfirmFee(record)}>
              {t('C')}
            </Button>
          );
        }
      },
    },
    {
      title: t('Unique ID'),
      key: 'unique_id',
      ellipsis: true, 
      render: (_, record) => {
        const loggedBooking = loggedBookings.find(logged => logged.test_booking_id === record.id);
        return loggedBooking ? loggedBooking.unique_id : 'N/A';
      },
    },
  ];

  return (
    <div style={{ padding: '20px' }}>
      <div style={{ marginBottom: '20px', display: 'flex', gap: '10px' }}>
        <DatePicker
          className="ant-datepicker"
          selected={selectedDate}
          onChange={handleDateChange}
          dateFormat="yyyy-MM-dd"
          placeholderText={t('Select Date')}
        />
        <Input.Search
          placeholder={t('Search by Student Name or National ID')}
          onSearch={handleSearch}
          enterButton
          style={{ maxWidth: '300px' }}
        />
        <Input.Search
  placeholder={t('Search by Unique ID')}
  onSearch={handleUniqueIdSearch}
  enterButton
  style={{ maxWidth: '300px', marginLeft: '10px' }}
/>

        {/* CSV Export Button */}
        <CSVLink
          data={csvData}
          headers={csvHeaders}
          filename="test_bookings.csv"
          className="ant-btn ant-btn-default"
          style={{ marginLeft: '10px' }}
        >
          <FontAwesomeIcon icon={faFileExcel} /> {t('exportToExcel')}
        </CSVLink>
      </div>

      <Spin spinning={loading}>
        <Table
          dataSource={filteredBookings}
          columns={columns}
          rowKey="id"
          scroll={{ x: true }}
          pagination={{
            pageSize: 50,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      </Spin>

      {selectedRecord && (
      <CertificateModal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)} 
        studentNameEnglish={`${selectedRecord.student_firstnameenglish || 'First'} ${selectedRecord.student_lastnameenglish || 'Last'}`}  
        courseName={selectedRecord.course_name || 'Thai Massage'}
        hours={selectedRecord.hours || 150}  
        uniqueId={selectedRecord.unique_id || 'uniqueid'}
      />
    )}
    </div>
  );
};

export default withRoleAccess(TestList, ['admin', 'superadmin', 'teacher']);
