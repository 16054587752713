import React, { useEffect, useState } from 'react';
import { Table, message, Input } from 'antd';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import 'antd/dist/reset.css';

const { Search } = Input;

const StudentMatch = () => {
  const { t } = useTranslation();
  const [studentsData, setStudentsData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // State to store filtered data
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState(''); // Search term state
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchStudentsPaymentStatus();
  }, []);

  // Fetch students payment status from API
  const fetchStudentsPaymentStatus = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/student-match/students-payment-status`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const { students, uniqueCourses } = transformData(response.data);
      setStudentsData(students);
      setCourses(uniqueCourses);
      setFilteredData(students); // Set initial filtered data as the full data
      message.success('Student payment status fetched successfully.');
    } catch (error) {
      message.error('Failed to fetch student payment status.');
      console.error('Error fetching students payment status:', error);
    } finally {
      setLoading(false);
    }
  };

  // Transform data to get unique courses and match student enrollment
  const transformData = (data) => {
    const uniqueCourses = new Set();
    const students = [];

    data.forEach((record) => {
      const { schoolid, firstnamethai, lastnamethai, nationalid, item_name, payment_status } = record;

      // Find or create a student record
      let studentRecord = students.find(student => student.schoolid === schoolid);
      if (!studentRecord) {
        studentRecord = {
          schoolid,
          studentName: `${firstnamethai} ${lastnamethai}`,
          nationalid,
        };
        students.push(studentRecord);
      }

      // Set payment status for the course (item_name)
      studentRecord[item_name] = payment_status; // Keep the original status

      // Collect unique course names
      uniqueCourses.add(item_name);
    });

    return { students, uniqueCourses: Array.from(uniqueCourses) };
  };

  // Filter data based on search term
  const handleSearch = (value) => {
    setSearchTerm(value.toLowerCase());
    const filtered = studentsData.filter(
      student =>
        student.studentName.toLowerCase().includes(value.toLowerCase()) ||
        student.nationalid.includes(value)
    );
    setFilteredData(filtered);
  };

  // Define static columns for student information
  const staticColumns = [
    {
      title: t('schoolid'),
      dataIndex: 'schoolid',
      key: 'schoolid',
    },
    {
      title: t('student'),
      dataIndex: 'studentName',
      key: 'studentName',
      render: (text) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {text}
        </div>
      ),
    },
    
    {
      title: t('nationalid'),
      dataIndex: 'nationalid',
      key: 'nationalid',
    },
  ];

  // Define dynamic columns for each course
  const dynamicColumns = courses.map((course) => ({
    title: course,
    dataIndex: course,
    key: course,
    render: (text) => {
      if (text === undefined) {
        // No enrollment record
        return <span style={{ color: '#777777' }}>{t('X')}</span>;
      } else if (text === 'fullypaid') {
        // Fully paid
        return <span style={{ color: 'green' }}>✔️ {t('fullypay')}</span>;
      } else {
        // Enrolled but not fully paid
        return <span style={{ color: 'red' }}>❌ {t('Unpaid')}</span>;
      }
    },
  }));

  // Combine static and dynamic columns
  const columns = [...staticColumns, ...dynamicColumns];

  return (
    <div className="student-payment-status-page">
      <Sidebar />
      <Header />
      <div className="content">

        <Search
          placeholder={t('searchByNameOrNationalID')}
          onSearch={handleSearch}
          enterButton
          style={{ marginBottom: '20px', maxWidth: '400px' }}
        />

        <Table
          dataSource={filteredData} // Use filteredData instead of studentsData
          columns={columns}
          rowKey="schoolid"
          loading={loading}
          scroll={{ x: true }}
          pagination={{
            pageSize: 50,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      </div>
    </div>
  );
};

export default withRoleAccess(StudentMatch, ['admin', 'superadmin']);
