import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Table, notification } from 'antd';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

function StudentPayment() {
  const { id } = useParams(); // Extract student ID from URL
  const { t } = useTranslation(); // Use translation if needed
  const [billingData, setBillingData] = useState([]);
  const [loading, setLoading] = useState(false);

// Function to format prices without the currency symbol
const formatPrice = (price) => {
    const formattedPrice = Number(price);
    return isNaN(formattedPrice)
      ? '0.00'
      : new Intl.NumberFormat('th-TH', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(formattedPrice);
  };
  

  const API_URL = process.env.REACT_APP_API_URL;
  useEffect(() => {
    const fetchBillingData = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/student-payment/sales-payment-reports/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setBillingData(response.data); // Set the response data for billing
      } catch (error) {
        console.error('Error fetching billing data:', error);
        notification.error({ message: t('Error fetching billing data') });
      } finally {
        setLoading(false);
      }
    };

    fetchBillingData();
  }, [id, API_URL, t]);

  // Group by document_no
  const groupByDocumentNo = (data) => {
    return data.reduce((acc, curr) => {
      const docNo = curr.document_no;
      if (!acc[docNo]) acc[docNo] = [];
      acc[docNo].push(curr);
      return acc;
    }, {});
  };

  const groupedBillingData = groupByDocumentNo(billingData);

  // Create rows for the table with collapsible rows
  const rows = Object.keys(groupedBillingData).map((docNo) => {
    const rowsForDoc = groupedBillingData[docNo];
    const sortedRows = rowsForDoc.sort((a, b) => moment(b.created_at) - moment(a.created_at)); // Sort by date
    return {
      ...sortedRows[0], // Use the most recent row
      allRows: sortedRows, // Keep all rows for expansion
    };
  });

// Main billing columns (only showing document_no, item_names, and final_amount in the parent row)
const billingColumns = [
    {
      title: t('document_no'),
      dataIndex: 'document_no',
      key: 'document_no',
    },
    {
      title: t('item'),
      dataIndex: 'item_names',
      key: 'item_names',
      render: (text, record) => {
        // Get unique item names by using Set to avoid duplicates
        const uniqueItemNames = [...new Set(record.allRows.map(row => row.item_names))];
        return uniqueItemNames.join(', ');
      },
    },
    {
      title: t('final_amount'),
      dataIndex: 'final_amount',
      key: 'final_amount',
      render: (amount) => formatPrice(amount),
    },
    {
      title: t('remaining_balance'),
      dataIndex: 'remaining_balance',
      key: 'remaining_balance',
      render: (text, record) => {
        // Get the latest remaining balance from the first row (most recent)
        const latestBalance = record.allRows[0]?.remaining_amount;
  
        // Format the balance and apply conditional styling
        const formattedBalance = formatPrice(latestBalance);
        const balanceStyle = latestBalance > 0 ? 'red' : 'green';
  
        return (
          <span style={{ color: balanceStyle, fontWeight: 'bold' }}>
            {formattedBalance}
          </span>
        );
      },
    },
  ];
  
 // Configuration for expandable rows
const expandableConfig = {
    expandedRowRender: (record) => {
      // Use all rows for the same document_no
      const allRows = record.allRows;
  
      return (
        <Table
          columns={[
            {
              title: null, // No title for 'Receipt No'
              dataIndex: 'invoice_no',
              key: 'invoice_no',
              render: (text) => <span>{text}</span>, // Display data, but no header
            },
            {
              title: null, // No title for 'Item Names'
              dataIndex: 'item_names',
              key: 'item_names',
              render: (text) => <span>{text}</span>, // Display data, but no header
            },
            {
              title: t('Already Paid'), // Show header only for this column
              dataIndex: 'payment_amount',
              key: 'payment_amount',
              render: (amount) => formatPrice(amount),
            },
            {
              title: null, // No title for 'Remaining Balance'
              dataIndex: 'remaining_amount',
              key: 'remaining_amount',
              render: (balance) => (
                <span style={{ color: balance > 0 ? 'red' : 'green', fontWeight: 'bold' }}>
                  {formatPrice(balance)}
                </span>
              ),
            },
          ]}
          dataSource={allRows}
          scroll={{ x: true }}
          pagination={{
            pageSize: 50,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          }}
          showHeader 
          rowKey="payment_id"
          className="custom-table" 
        />
      );
    },
    rowExpandable: (record) => record.allRows.length > 0, 
  };
  
  
  // Example JSX for rendering the table
  return (
    <Table
      dataSource={rows} // Use the grouped rows data
      columns={billingColumns}
      rowKey="document_no"
      expandable={expandableConfig} // Apply expandable configuration
      loading={loading}
      pagination={{ pageSize: 50 }}
    />
  );
  
}

export default withRoleAccess(StudentPayment, ['admin', 'superadmin']);
