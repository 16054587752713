import React, { useState, useEffect, useCallback } from 'react';
import { Card, Table, Button, Spin, Input, DatePicker, Row, Col, Statistic } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import withRoleAccess from '../../hoc/withRoleAccess';

const { RangePicker } = DatePicker;

const ScholarshipPayment = () => {
  const { t } = useTranslation();
  const [scholarships, setScholarships] = useState([]);
  const [filteredScholarships, setFilteredScholarships] = useState([]);
  const [loading, setLoading] = useState(true);
  const [summaryStats, setSummaryStats] = useState({
    totalScholarship: 0,
    totalPayback: 0,
    totalNonPayback: 0,
    scholarshipThisMonth: 0,
    paybackThisMonth: 0,
    unpaidOver6Months: 0,
    unpaidOverYear: 0,
  });
  const [dateRange, setDateRange] = useState([null, null]);
  const [searchTerm, setSearchTerm] = useState('');
  const [documentNoSearch, setDocumentNoSearch] = useState('');
  const API_URL = process.env.REACT_APP_API_URL;

  const processScholarshipData = useCallback((payments, transactions) => {
    const paymentMap = new Map();
    
    payments.filter(Boolean).forEach(p => {
      if (!paymentMap.has(p.document_no)) {
        paymentMap.set(p.document_no, []);
      }
      paymentMap.get(p.document_no).push(p);
    });
  
    return transactions.filter(Boolean).map(t => {
      const relatedPayments = paymentMap.get(t.document_no) || [];
      
      if (relatedPayments.length === 0) {
        return {
          ...t,
          transaction_date: moment(t.transaction_date).format('YYYY-MM-DD'),
          remaining_amount: t.final_amount,
          paid_amount: 0,
          unique_key: `${t.document_no}_unpaid`,
        };
      }
      
      return relatedPayments.map((payment, index) => ({
        ...t,
        ...payment,
        transaction_date: moment(t.transaction_date).format('YYYY-MM-DD'),
        remaining_amount: payment.remaining_amount,
        paid_amount: payment.payment_amount,
        unique_key: `${t.document_no}_${index}`,
      }));
    }).flat();
  }, []);

  const calculateSummaryStats = useCallback((scholarships) => {
    const thisMonthStart = moment().startOf('month');
    const sixMonthsAgo = moment().subtract(6, 'months');
    const oneYearAgo = moment().subtract(1, 'year');
    
    const uniqueScholarships = new Map();
    const uniqueScholarshipsThisMonth = new Map();
    const remainingBalances = new Map();
  
    const stats = scholarships.reduce((acc, scholarship) => {
      const scholarshipDate = moment(scholarship.transaction_date);
      const scholarshipAmount = Number(scholarship.final_amount);
      const paidAmount = Number(scholarship.paid_amount);
      const remainingAmount = Number(scholarship.remaining_amount);
      const documentNo = scholarship.document_no;
  
      // Handle total scholarship amount (considering unique document_no)
      if (!uniqueScholarships.has(documentNo) || scholarshipAmount > uniqueScholarships.get(documentNo)) {
        if (uniqueScholarships.has(documentNo)) {
          acc.totalScholarship -= uniqueScholarships.get(documentNo);
        }
        acc.totalScholarship += scholarshipAmount;
        uniqueScholarships.set(documentNo, scholarshipAmount);
      }
  
      // Accumulate total payback
      acc.totalPayback += paidAmount;
  
      // Track the smallest remaining balance for each document_no
      if (!remainingBalances.has(documentNo) || remainingAmount < remainingBalances.get(documentNo)) {
        remainingBalances.set(documentNo, remainingAmount);
      }
  
      // Handle scholarship amount for this month (considering unique document_no)
      if (scholarshipDate.isSameOrAfter(thisMonthStart)) {
        if (!uniqueScholarshipsThisMonth.has(documentNo) || scholarshipAmount > uniqueScholarshipsThisMonth.get(documentNo)) {
          if (uniqueScholarshipsThisMonth.has(documentNo)) {
            acc.scholarshipThisMonth -= uniqueScholarshipsThisMonth.get(documentNo);
          }
          acc.scholarshipThisMonth += scholarshipAmount;
          uniqueScholarshipsThisMonth.set(documentNo, scholarshipAmount);
        }
        acc.paybackThisMonth += paidAmount;
      }
  
      return acc;
    }, {
      totalScholarship: 0,
      totalPayback: 0,
      totalNonPayback: 0,
      scholarshipThisMonth: 0,
      paybackThisMonth: 0,
      unpaidOver6Months: 0,
      unpaidOverYear: 0,
    });
  
    // Calculate total non-payback using the smallest remaining balance for each document_no
    stats.totalNonPayback = Array.from(remainingBalances.values()).reduce((sum, balance) => sum + balance, 0);
  
    // Calculate unpaid over 6 months and 1 year
    scholarships.forEach(scholarship => {
      const scholarshipDate = moment(scholarship.transaction_date);
      const remainingAmount = remainingBalances.get(scholarship.document_no);
      
      if (scholarshipDate.isBefore(sixMonthsAgo) && remainingAmount > 0) {
        stats.unpaidOver6Months += remainingAmount;
        if (scholarshipDate.isBefore(oneYearAgo)) {
          stats.unpaidOverYear += remainingAmount;
        }
      }
    });
  
    setSummaryStats(stats);
  }, []);
  
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const [incomeReportsResponse, salesTransactionsResponse] = await Promise.all([
        axios.get(`${API_URL}/payment/income-reports`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { startDate: null, endDate: null, schoolId: null }
        }),
        axios.get(`${API_URL}/sales/sales-transactions`, {
          headers: { Authorization: `Bearer ${token}` }
        })
      ]);
  
      const scholarshipPayments = incomeReportsResponse.data.filter(payment => 
        payment.sales_payment_method === "scholarship"
      );
  
      const scholarshipTransactions = salesTransactionsResponse.data.filter(transaction => 
        transaction.payment_method === "scholarship"
      );
  
      const combinedScholarships = processScholarshipData(scholarshipPayments, scholarshipTransactions);
      
      setScholarships(combinedScholarships);
      setFilteredScholarships(combinedScholarships);
      calculateSummaryStats(combinedScholarships);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  }, [API_URL, processScholarshipData, calculateSummaryStats]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const applyFilters = useCallback(() => {
    let filtered = scholarships;
  
    if (dateRange && dateRange[0] && dateRange[1]) {
      const [start, end] = dateRange;
      filtered = filtered.filter(scholarship => {
        const scholarshipDate = moment(scholarship.transaction_date, 'YYYY-MM-DD'); // Assume ISO format from backend
        return scholarshipDate.isBetween(moment(start).startOf('day'), moment(end).endOf('day'), 'day', '[]');
      });
    }
  
    if (searchTerm) {
      filtered = filtered.filter(scholarship =>
        (scholarship.student_name && scholarship.student_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (scholarship.nationalid && scholarship.nationalid.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }
  
    if (documentNoSearch) {
      filtered = filtered.filter(scholarship =>
        scholarship.document_no.toLowerCase().includes(documentNoSearch.toLowerCase())
      );
    }
  
    setFilteredScholarships(filtered);
}, [scholarships, dateRange, searchTerm, documentNoSearch]);

  useEffect(() => {
    applyFilters();
  }, [applyFilters]);

  const handleDateRangeChange = (dates) => {
    setDateRange(dates || [null, null]);
  };

  const handleSearch = (value, type) => {
    if (type === 'nameOrId') {
      setSearchTerm(value);
    } else if (type === 'documentNo') {
      setDocumentNoSearch(value);
    }
  };

  const columns = [
    {
      title: t('document_no'),
      dataIndex: 'document_no',
      key: 'document_no',
    },
    {
      title: t('transaction_date'),
      dataIndex: 'transaction_date',
      key: 'transaction_date',
      render: (date) => moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY'), 
    },
    {
      title: t('student_name'),
      dataIndex: 'student_name',
      key: 'student_name',
    },
    {
      title: t('nationalid'),
      dataIndex: 'nationalid',
      key: 'nationalid',
    },
    {
      title: t('final_amount'),
      dataIndex: 'final_amount',
      key: 'final_amount',
      render: (amount) => (
        <span style={{ color: '#0050B3', fontWeight: 'bold' }}>
          {new Intl.NumberFormat('th-TH', { style: 'currency', currency: 'THB' }).format(amount)}
        </span>
      ),
    },
    {
      title: t('paid_amount'),
      dataIndex: 'paid_amount',
      key: 'paid_amount',
      render: (amount) => (
        <span style={{ color: '#52c41a', fontWeight: 'bold' }}>
          {new Intl.NumberFormat('th-TH', { style: 'currency', currency: 'THB' }).format(amount)}
        </span>
      ),
    },
    {
      title: t('remaining_balance'),
      dataIndex: 'remaining_amount',
      key: 'remaining_amount',
      render: (amount) => (
        <span style={{ color: '#cf1322', fontWeight: 'bold' }}>
          {new Intl.NumberFormat('th-TH', { style: 'currency', currency: 'THB' }).format(amount)}
        </span>
      ),
    },
  ];

  const csvData = filteredScholarships.map(scholarship => ({
    document_no: scholarship.document_no,
    student_name: scholarship.student_name,
    nationalid: scholarship.nationalid,
    final_amount: scholarship.final_amount,
    paid_amount: scholarship.paid_amount,
    remaining_amount: scholarship.remaining_amount,
    date: moment(scholarship.transaction_date).format('DD/MM/YYYY'),
  }));

  return (
    <div>
      <h1>{t('scholarshipPayment')}</h1>
      
      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col span={4}>
          <Card style={{ backgroundColor: '#E6F7FF', borderColor: '#1890FF' }}>
            <Statistic 
              title={t('totalScholarship')} 
              value={summaryStats.totalScholarship} 
              prefix="฿" 
              valueStyle={{ color: '#0050B3' }}
            />
          </Card>
        </Col>
        <Col span={4}>
          <Card style={{ backgroundColor: '#f6ffed', borderColor: '#b7eb8f' }}>
            <Statistic 
              title={t('totalPayback')} 
              value={summaryStats.totalPayback} 
              prefix="฿"
              valueStyle={{ color: '#52c41a' }}
            />
          </Card>
        </Col>
        <Col span={4}>
          <Card style={{ backgroundColor: '#fff1f0', borderColor: '#ffa39e' }}>
            <Statistic 
              title={t('totalNonPayback')} 
              value={summaryStats.totalNonPayback} 
              prefix="฿"
              valueStyle={{ color: '#cf1322' }}
            />
          </Card>
        </Col>
        <Col span={4}>
          <Card style={{ backgroundColor: '#E6F7FF', borderColor: '#1890FF' }}>
            <Statistic title={t('scholarshipThisMonth')} value={summaryStats.scholarshipThisMonth} prefix="฿" />
          </Card>
        </Col>
        <Col span={4}>
          <Card style={{ backgroundColor: '#f6ffed', borderColor: '#b7eb8f' }}>
            <Statistic title={t('paybackThisMonth')} value={summaryStats.paybackThisMonth} prefix="฿" />
          </Card>
        </Col>
        <Col span={4}>
          <Card style={{ backgroundColor: '#fff2e8', borderColor: '#ffbb96' }}>
            <Statistic 
              title={t('unpaidOver6Months')} 
              value={summaryStats.unpaidOver6Months} 
              prefix="฿"
              valueStyle={{ color: '#fa541c' }}
            />
            <Statistic 
              title={t('unpaidOverYear')} 
              value={summaryStats.unpaidOverYear} 
              prefix="฿"
              valueStyle={{ color: '#d4380d' }}
            />
          </Card>
        </Col>
      </Row>

      <div style={{ marginBottom: '20px', display: 'flex', gap: '10px' }}>
      <RangePicker
            value={dateRange[0] && dateRange[1] ? [moment(dateRange[0]), moment(dateRange[1])] : undefined}
            onChange={(dates) => handleDateRangeChange(dates ? [dates[0].toDate(), dates[1].toDate()] : [null, null])}
            format="DD/MM/YYYY"
            placeholder={[t('startDate'), t('endDate')]}
            allowClear={true}
            />
        <Input.Search
          placeholder={t('searchByNameOrNationalID')}
          onSearch={(value) => handleSearch(value, 'nameOrId')}
          enterButton
          style={{ width: 250 }}
        />
        <Input.Search
          placeholder={t('searchByDocumentNo')}
          onSearch={(value) => handleSearch(value, 'documentNo')}
          enterButton
          style={{ width: 250 }}
        />
        <CSVLink data={csvData} filename="scholarship_payments_report.csv">
          <Button icon={<FileExcelOutlined />}>
            {t('exportToExcel')}
          </Button>
        </CSVLink>
      </div>

      {loading ? (
        <Spin tip={t('loading')} />
      ) : (
        <Table 
            columns={columns} 
            dataSource={filteredScholarships} 
            rowKey="unique_key"
          pagination={{
            pageSize: 50,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      )}
    </div>
  );
};

export default withRoleAccess(ScholarshipPayment, ['superadmin', 'admin']);