import React from 'react';
import { Tabs } from 'antd';
import TestBooking from './TestBooking';
import TestResult from './TestResult';
import TestList from './TestList';
import TestBookingPage from './TestBookingPage';
import { useTranslation } from 'react-i18next';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import withRoleAccess from '../../hoc/withRoleAccess';
import './Test.css'; // Import the CSS file

const { TabPane } = Tabs;

const MainTest = () => {

  const { t } = useTranslation();
  return (
    <div className="main-test-container">
            <Sidebar />
            <Header />
      <Tabs defaultActiveKey="1">
      <TabPane tab={t('Book Test')} key="1">
        <TestBookingPage />
      </TabPane>
      <TabPane tab={t('Test List')} key="2">
        <TestBooking />
      </TabPane>
      <TabPane tab={t('Add Test Result')} key="3">
        <TestResult />
      </TabPane>
      <TabPane tab={t('Pass List')} key="4">
        <TestList />
      </TabPane>
    </Tabs>
    </div>
  );
};
export default withRoleAccess(MainTest, ['admin', 'superadmin', 'teacher']);

