import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Pagination, Typography, Spin, Input} from 'antd';
import { CSVLink } from 'react-csv'; // Import CSVLink from react-csv
import './InventoryPage.css';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

const { Title } = Typography;
const { Search } = Input;

const InventoryReport = () => {
    const { t, i18n } = useTranslation();
    const [reportData, setReportData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 50;
    const [dateRange, setDateRange] = useState([null, null]);
    const [searchTerm, setSearchTerm] = useState(''); // State for search input
    const API_URL = process.env.REACT_APP_API_URL;

    const formatDateTime = (dateString) => {
        const date = new Date(dateString);
        return isNaN(date.getTime()) ? 'Invalid Date' : date.toLocaleString(i18n.language, {
            year: 'numeric',
            month: 'short', // Full month name
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false, // Use 24-hour format
        });
    };

    useEffect(() => {
        fetchReportData();
    }, [API_URL]);

    const fetchReportData = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            
            const response = await axios.get(`${API_URL}/inventory/inventory-report-page`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
    
            console.log('Response data:', response.data);
            const sortedData = response.data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
            setReportData(sortedData);
            setFilteredData(sortedData); // Initialize filtered data
            setLoading(false);
        } catch (err) {
            console.error('Error fetching inventory report:', err);
            setError(t('fetch_inventory_report_error'));
            setLoading(false);
        }
    };
    
    
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleDateRangeChange = (update) => {
        setDateRange(update);
        
        if (update[0] && update[1]) {
            const startDate = moment(update[0]).startOf('day');
            const endDate = moment(update[1]).endOf('day');

            const filtered = reportData.filter(item => {
                const itemDate = moment(item.timestamp);
                return itemDate.isBetween(startDate, endDate, null, '[]');
            });

            setFilteredData(filtered);
            setCurrentPage(1); // Reset to first page after filtering
        } else {
            setFilteredData(reportData); // Reset to all data if date range is cleared
        }
    };

    // Search functionality to filter by product_id or item_name
    const handleSearch = (value) => {
        setSearchTerm(value); // Update search term state

        const filtered = reportData.filter((item) => {
            const searchValue = value.toLowerCase();
            return (
                item.product_id.toString().toLowerCase().includes(searchValue) ||
                item.item_name.toLowerCase().includes(searchValue)
            );
        });

        setFilteredData(filtered); // Update filtered data based on search term
        setCurrentPage(1); // Reset to the first page after filtering
    };

    const columns = [
        {
            title: t('productid'),
            dataIndex: 'product_id',
            key: 'product_id',
        },
        {
            title: t('itemname'),
            dataIndex: 'item_name',
            key: 'item_name',
        },
        {
            title: t('document_no'),
            dataIndex: 'document_no',
            key: 'document_no',
        },
        {
            title: t('type'),
            dataIndex: 'transaction_type',
            key: 'transaction_type',
        },
        {
            title: t('unit'),
            dataIndex: 'quantity_change',
            key: 'quantity_change',
        },
        {
            title: t('changereason'),
            dataIndex: 'change_reason',
            key: 'change_reason',
        },
        {
            title: t('date'),
            dataIndex: 'timestamp',
            key: 'timestamp',
            render: (timestamp, record, index) => formatDateTime(timestamp, 'th-TH'),
        },
        {
            title: t('updatedby'),
            dataIndex: 'updated_by',
            key: 'updated_by',
        },
    ];

    return (
        <div className="inventory-report-container">
            <Sidebar />
            <Header />
            <div className="inventory-report-content">
                <Title level={2}>{t('Inventory Report')}</Title>

                <div className="filter-section" style={{ marginBottom: 20 }}>
                    {/* Date Range Picker */}
                    <DatePicker
                        selectsRange={true}
                        startDate={dateRange[0]}
                        endDate={dateRange[1]}
                        onChange={(update) => handleDateRangeChange(update)}
                        isClearable={true}
                        dateFormat="dd-MM-yyyy"
                        placeholderText={t('Select Date Range')}
                    />

                    {/* Search Box for Product ID or Item Name */}
                    <Search
                        placeholder={t('Search Product ID or Item Name')}
                        onSearch={handleSearch}
                        enterButton
                        allowClear
                        style={{ maxWidth: '300px', marginLeft: 10 }}
                    />

                    {/* CSV Export Button */}
                    <CSVLink
                        data={filteredData}
                        headers={[
                            { label: t('productid'), key: 'product_id' },
                            { label: t('itemname'), key: 'item_name' },
                            { label: t('document_no'), key: 'document_no' },
                            { label: t('type'), key: 'transaction_type' },
                            { label: t('unit'), key: 'quantity_change' },
                            { label: t('changereason'), key: 'change_reason' },
                            { label: t('date'), key: 'timestamp' },
                            { label: t('updated_by'), key: 'nickname' },
                        ]}
                        filename="inventory_report.csv"
                        className="ant-btn ant-btn-default"
                        style={{ marginLeft: 10 }}
                    >
                        <FontAwesomeIcon icon={faFileExcel} /> {t('exportToExcel')}
                    </CSVLink>
                </div>

                {loading ? (
                    <Spin size="large" />
                ) : error ? (
                    <p>{error}</p>
                ) : (
                    <>
                         <Table
                            columns={columns}
                            dataSource={filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)}
                            scroll={{ x: true }}
                            pagination={{
                                pageSize: 50,
                                showSizeChanger: true,
                                showQuickJumper: true,
                                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                            }}
                            rowKey={(record, index) => `${record.timestamp}-${record.product_id}-${index}`}
                        />
                        <Pagination
                            current={currentPage}
                            pageSize={itemsPerPage}
                            total={filteredData.length}
                            onChange={handlePageChange}
                            className="inventory-pagination"
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default withRoleAccess(InventoryReport, ['superadmin']);
