import React, { useState } from 'react';
import { Form, Input, Button, Select, Upload, DatePicker, message, ConfigProvider } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import th_TH from 'antd/lib/locale/th_TH';

dayjs.locale('th');

const { Option } = Select;

const AddExpense = () => {
  const { t } = useTranslation();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const API_URL = process.env.REACT_APP_API_URL;

  const handleUpload = (file) => {
    setFile(file);
    return false;
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('th-TH', { style: 'currency', currency: 'THB' }).format(value);
  };

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append('expense_type', values.expense_type);
    formData.append('category_id', values.category_id);
    formData.append('amount', parseFloat(values.amount.replace(/[^0-9.]/g, '')));
    formData.append('description', values.description);
    formData.append('date', dayjs(values.date).format('YYYY-MM-DD'));
    formData.append('month_year', dayjs(values.month_year).format('YYYY-MM'));
    if (file) {
      formData.append('receipt', file);
    }
  
    const token = localStorage.getItem('token');

  
    try {
      setLoading(true);
  
      // Make the request and log the result
      const response = await axios.post(`${API_URL}/expense`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
  
  
      message.success(t('expense_added_successfully'));
  
      // Reset the form
      form.resetFields();
  
      // Reset the file state
      setFile(null);
  
    } catch (error) {
      console.error('Error:', error); // Log the error object
  
      message.error(t('add_expense_failed'));
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <ConfigProvider locale={th_TH}>
      <Form layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          name="month_year"
          label={t('month_year_label')}
          rules={[{ required: true, message: t('month_year_required') }]}
        >
          <DatePicker picker="month" format="MM/YYYY" />
        </Form.Item>
                {/* Date Picker */}
                <Form.Item
          name="date"
          label={t('date_expense')}
          rules={[{ required: true, message: t('date_required') }]}
        >
          <DatePicker format="DD/MM/YYYY" />
        </Form.Item>

        <Form.Item
          name="category_id"
          label={t('category_label')}
          rules={[{ required: true, message: t('category_required') }]}
        >
          <Select placeholder={t('select_category')}>
            <Option value="1">{t('postoffice')}</Option>
            <Option value="2">{t('transport')}</Option>
            <Option value="3">{t('gas')}</Option>
            <Option value="4">{t('laundry')}</Option>
            <Option value="5">{t('utilities')}</Option>
            <Option value="6">{t('return')}</Option>
            <Option value="7">{t('Facebook')}</Option> 
            <Option value="8">{t('Tiktok')}</Option>
            <Option value="9">{t('others')}</Option>
          </Select>
        </Form.Item>

        {/* Expense Type Input */}
        <Form.Item
          name="expense_type"
          label={t('details')} // Use translation for label
          rules={[{ required: true, message: t('type_required') }]} // Use translation for validation message
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="amount"
          label={t('amount_label')}
          rules={[{ required: true, message: t('amount_required') }]}
        >
          <Input
            onChange={(e) => {
              const numericValue = e.target.value.replace(/[^0-9.]/g, '');
              form.setFieldsValue({ amount: numericValue });
            }}
            onBlur={(e) => {
              const numericValue = parseFloat(e.target.value);
              if (!isNaN(numericValue)) {
                const formattedValue = formatCurrency(numericValue);
                form.setFieldsValue({ amount: formattedValue });
              }
            }}
          />
        </Form.Item>

        <Form.Item name="description" label={t('memo')}>
          <Input.TextArea rows={4} />
        </Form.Item>

        <Form.Item label={t('upload_receipt_label')}>
          <Upload beforeUpload={handleUpload} maxCount={1} accept=".pdf">
            <Button icon={<UploadOutlined />}>{t('upload_button')}</Button>
          </Upload>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t('submit_button')}
          </Button>
        </Form.Item>
      </Form>
    </ConfigProvider>
  );
};

export default withRoleAccess(AddExpense, ['superadmin', 'admin']);
