import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import './GraduateGroup.css';
import withRoleAccess from '../../hoc/withRoleAccess';
import { Table, Spin, notification, Input, Button, Select } from 'antd'; 
import { useTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const { Search } = Input;
const { Option } = Select; 

const GraduateList = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [graduationRecords, setGraduationRecords] = useState({});
  const [selectedGroups, setSelectedGroups] = useState({});
  const [confirmSelections, setConfirmSelections] = useState({});
  const [studentGraduationGroups, setStudentGraduationGroups] = useState({});
  const [latestEndDateMap, setLatestEndDateMap] = useState({}); 
  const [holdSelections, setHoldSelections] = useState({});
  const [salesData, setSalesData] = useState({});


  const API_URL = process.env.REACT_APP_API_URL;

  const fetchAssignedStudents = async () => {
    try {
      const token = localStorage.getItem('token');
  
      // Fetch assigned students
      const assignedStudentsResponse = await axios.get(`${API_URL}/graduate-group/assigned-students`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      if (assignedStudentsResponse.status === 200 && assignedStudentsResponse.data.length === 0) {
        return { assignedStudentMap: {}, latestEndDateMapTemp: {} }; // Return empty map
      }
  
      const assignedStudents = assignedStudentsResponse.data;
      const assignedStudentMap = {};
      const latestEndDateMapTemp = {};
  
      // Map the assigned students data
      assignedStudents.forEach(item => {
        assignedStudentMap[`${item.student_id}-${item.course_id}`] = item;
  
        // Update latest end date for the student
        if (!latestEndDateMapTemp[item.student_id] || moment(item.end_date).isAfter(latestEndDateMapTemp[item.student_id])) {
          latestEndDateMapTemp[item.student_id] = item.end_date;
        }
      });
  
      return { assignedStudentMap, latestEndDateMapTemp };
    } catch (error) {
      if (error.response && error.response.status === 404) {
      } else {
        console.error('Error fetching assigned students:', error);
      }
  
      return { assignedStudentMap: {}, latestEndDateMapTemp: {} }; // Return empty objects if an error occurs
    }
  };
  
  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
  
      console.log('Fetching students, sales data, and graduation records...');
      
      // Fetch students, courses, and sales data in parallel
      const [studentsResponse, salesResponse] = await Promise.all([
        axios.get(`${API_URL}/graduate-group/students-with-invoices-and-courses`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`${API_URL}/graduate-group/sales-with-student-details`, {
          headers: { Authorization: `Bearer ${token}` },
        })
      ]);
  
      let fetchedData = studentsResponse.data;
      console.log('Students data:', fetchedData);
  
      const salesInfo = salesResponse.data;
      console.log('Sales data:', salesInfo);
  
      // Create a map of sales data using `schoolid` instead of `student_id`
      const salesMap = salesInfo.reduce((acc, sale) => {
        acc[sale.schoolid] = { price: sale.total_price, transaction_id: sale.transaction_id };
        return acc;
      }, {});
      console.log('Sales map:', salesMap);
  
      setSalesData(salesMap);
  
      // Fetch graduation records based on unique `course_id` from fetchedData
      const courseIds = [...new Set(fetchedData.map(item => item.course_id))];
      const graduationData = {};
  
      await Promise.all(
        courseIds.map(async (courseId) => {
          if (!courseId) return;
          const graduationResponse = await axios.get(`${API_URL}/graduate-group/graduation-records`, {
            headers: { Authorization: `Bearer ${token}` },
            params: { course_id: courseId },
          });
          graduationData[courseId] = graduationResponse.data || [];
        })
      );
      
      console.log('Graduation data:', graduationData);
      setGraduationRecords(graduationData);
  
      // Fetch assigned students and the latest end date map
      const { assignedStudentMap, latestEndDateMapTemp } = await fetchAssignedStudents();
  
      // Sort the data based on whether the student is in the assignedStudentMap
      fetchedData = fetchedData.sort((a, b) => {
        const aAssigned = !!assignedStudentMap[`${a.studentid}-${a.course_id}`]; // True if assigned
        const bAssigned = !!assignedStudentMap[`${b.studentid}-${b.course_id}`]; // True if assigned
        return aAssigned - bAssigned; // Unassigned (false) should come before assigned (true)
      });
  
      // Update the state with the fetched and processed data
      setData(fetchedData);
      setFilteredData(fetchedData);
      setStudentGraduationGroups(assignedStudentMap);
      setLatestEndDateMap(latestEndDateMapTemp);
  
    } catch (error) {
      console.error('Error fetching data:', error);
      notification.error({ message: t('Error fetching data') });
    } finally {
      setLoading(false);
    }
  }, [API_URL, t]);
  
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  

  const handleSearch = (value) => {
    const searchTerm = value.toLowerCase();

    // Filter data based on name or national ID match
    const filtered = data.filter(
      student =>
        (student.firstnamethai && student.firstnamethai.toLowerCase().includes(searchTerm)) ||
        (student.lastnamethai && student.lastnamethai.toLowerCase().includes(searchTerm)) ||
        (student.nationalid && student.nationalid.toLowerCase().includes(searchTerm))
    );

    setFilteredData(filtered); // Update the filtered data
  };


  const handleGroupChange = (group_name, courseId, studentId) => {
    setSelectedGroups((prevState) => ({
      ...prevState,
      [`${studentId}-${courseId}`]: group_name, 
    }));
  };

  const handleConfirm = async (courseId, studentId) => {
    const group = selectedGroups[`${studentId}-${courseId}`];
    const selectedCourse = data.find(item => item.course_id === courseId);
    const courseName = selectedCourse ? selectedCourse.test_course_name : undefined;
    const graduationRecord = graduationRecords[courseId].find(record => record.group_name === group);
    const graduationGroupId = graduationRecord ? graduationRecord.id : undefined;
  
    if (!group || !graduationGroupId) {
      console.error('No valid group or graduation group ID found');
      return notification.error({ message: t('Please select a valid group') });
    }
  
    confirmAlert({
      title: t('Confirm Addition'),
      message: t('Are you sure you want to add this student to the graduation group?'),
      buttons: [
        {
          label: t('yes'),
          onClick: async () => {
            try {
              const token = localStorage.getItem('token');
  
              await axios.post(`${API_URL}/graduate-group/add-student-to-graduation`, {
                student_id: studentId,
                course_id: courseId,
                group_name: group,
                course_name: courseName,
                graduation_group_id: graduationGroupId,
              }, {
                headers: { Authorization: `Bearer ${token}` }
              });
  
              notification.success({ message: t('Student added to graduation group successfully') });
  
              setConfirmSelections((prevState) => ({
                ...prevState,
                [`${studentId}-${courseId}`]: true,
              }));
  
              await fetchData();
  
            } catch (error) {
              console.error('Error adding student to graduation group:', error);
              notification.error({ message: t('Failed to add student to graduation group') });
            }
          }
        },
        {
          label: t('No'),
          onClick: () => {
          }
        }
      ]
    });
  };
  const handleUnconfirm = async (courseId, studentId) => {
    confirmAlert({
      title: t('Confirm Removal'),
      message: t('Are you sure you want to unconfirm this student from the graduation group?'),
      buttons: [
        {
          label: t('yes'),
          onClick: async () => {
            try {
              const token = localStorage.getItem('token');
              const response = await axios.delete(`${API_URL}/graduate-group/remove-student-from-graduation`, {
                headers: { Authorization: `Bearer ${token}` },
                data: {
                  student_id: studentId,
                  course_id: courseId,
                }
              });
  
              notification.success({ message: t('Student removed from graduation group successfully') });
  
              setConfirmSelections((prevState) => ({
                ...prevState,
                [`${studentId}-${courseId}`]: false,
              }));
  
              await fetchData(); // Refresh the data
  
            } catch (error) {
              console.error('Error removing student from graduation group:', error);
              notification.error({ message: t('Failed to remove student from graduation group') });
            }
          }
        },
        {
          label: t('No'),
          onClick: () => {}
        }
      ]
    });
  };
  
  
  const columns = [
    {
      title: t('schoolid'),
      dataIndex: 'schoolid',
      key: 'schoolid',
    },
    {
      title: t('ชำระค่าขึ้นทะเบียน สบส'),
      key: 'payment_status',
      render: (record) => {
        const saleInfo = salesData[record.schoolid];
        if (saleInfo && saleInfo.price === '890.00') {
          return (
            <div>
              <span style={{ color: 'green' }}>
                {t('Already Paid')}
              </span>
              <br />
              <span style={{ color: 'green' }}>
                ({t('transaction_id')}: {saleInfo.transaction_id})
              </span>
            </div>
          );
        }
        return t('Not Paid');
      },
    },
          
    {
      title: t('student'),
      key: 'name',
      render: (record) => `${record.firstnamethai} ${record.lastnamethai}`,
    },
    {
      title: t('nationalid'),
      dataIndex: 'nationalid',
      key: 'nationalid',
    },
    {
      title: t('course'),
      dataIndex: 'inventory_item_name',
      key: 'inventory_item_name',
    },
    {
      title: t('Already Paid'),
      dataIndex: 'total_paid_amount',
      key: 'total_paid_amount',
      render: (amount) => `${parseFloat(amount).toFixed(2)}`,
    },
    {
      title: t('Group Info'),
      key: 'group_info',
      render: (record) => {
        const courseId = record.course_id;
        const studentId = record.studentid;
        const assignedStudent = studentGraduationGroups[`${studentId}-${courseId}`];
        const latestEndDate = latestEndDateMap[studentId] ? moment(latestEndDateMap[studentId]) : null;
        const graduationRecord = graduationRecords[courseId] || [];
        const isHold = holdSelections[`${studentId}-${courseId}`]; // Check if the entry is on hold
        const isConfirmed = confirmSelections[`${studentId}-${courseId}`]; // Check if the group is confirmed
    
        if (assignedStudent) {
          // If the student is already assigned to a group, show the confirmed info and the Unconfirm button
          return (
            <div>
              <p>{assignedStudent.group_name} ({moment(assignedStudent.start_date).format('DD-MM-YYYY')} - {moment(assignedStudent.end_date).format('DD-MM-YYYY')})</p>
              <Button onClick={() => handleUnconfirm(courseId, studentId)}>{t('Unconfirm')}</Button>
            </div>
          );
        }
    
        // If the student has passed and paid, allow group selection and confirmation
        if (record.pass_status === 'Pass' && record.total_paid_amount > 0) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Select
                placeholder={t('Select Group')}
                value={selectedGroups[`${studentId}-${courseId}`] || undefined}
                onChange={(group_name) => handleGroupChange(group_name, courseId, studentId)}
                style={{ width: 200 }}
                disabled={isHold} // Entire dropdown is disabled when on hold
              >
                {graduationRecord
                  .filter(group => !latestEndDate || moment(group.start_date).isAfter(latestEndDate)) // Only show valid groups
                  .map((group) => (
                    <Option key={group.group_name} value={group.group_name}>
                      {group.group_name} ({moment(group.start_date).format('DD-MM-YYYY')} - {moment(group.end_date).format('DD-MM-YYYY')})
                    </Option>
                  ))}
              </Select>
    
              {/* Disable confirm when hold is true or already confirmed */}
              <Button
                onClick={() => handleConfirm(courseId, studentId)}
                style={{ marginLeft: '10px' }}
                disabled={isHold || isConfirmed}  // Disable Confirm when hold is true or already confirmed
              >
                {isConfirmed ? t('Confirmed') : t('Confirm')}
              </Button>
            </div>
          );
        }
    
        return t('No Graduation Info');
      },
    },    
      {
        title: t('Hold Status'),
        key: 'hold_status',
        render: (record) => {
          const courseId = record.course_id;
          const studentId = record.studentid;
          const isHold = holdSelections[`${studentId}-${courseId}`]; // Check if the entry is on hold
          const isConfirmed = confirmSelections[`${studentId}-${courseId}`]; // Check if the group is confirmed
      
          // Only show Hold/Unhold button if the group is not confirmed
          if (!isConfirmed) {
            return (
              <Button
                onClick={() => handleHoldToggle(courseId, studentId)}
                style={{
                  marginLeft: '10px',
                  backgroundColor: isHold ? '#FF69B4' : '#bcbcbc', // Darker pink for Unhold, lighter pink for Hold
                  borderColor: isHold ? '#FFFFFF' : '#FFB6C1', // Match border to background color
                  color: 'white', // White text for contrast
                }}
              >
                {isHold ? t('Unhold') : t('Hold')}
              </Button>
            );
          }
      
          // Return null if the group is confirmed
          return null;
        },
      },
      
    ];
    
    // Add these functions to your frontend
    const fetchHoldStatus = async (studentId, courseId) => {
      try {
        const token = localStorage.getItem('token'); // Assuming you store token in localStorage
        const response = await axios.get(`${API_URL}/graduate-group/hold-status`, {
          params: { student_id: studentId, course_id: courseId },
          headers: { Authorization: `Bearer ${token}` }, // Include Bearer token
        });
    
        const { is_hold } = response.data;
        setHoldSelections((prevState) => ({
          ...prevState,
          [`${studentId}-${courseId}`]: is_hold, // Store the hold status in the state
        }));
      } catch (error) {
        console.error('Error fetching hold status:', error);
        notification.error({ message: 'Failed to fetch hold status' });
      }
    };
    
    const handleHoldToggle = async (courseId, studentId) => {
      try {
        const token = localStorage.getItem('token'); // Fetch token from localStorage
        const isHold = holdSelections[`${studentId}-${courseId}`]; // Get current hold status
    
        await axios.put(`${API_URL}/graduate-group/hold-status`, {
          student_id: studentId,
          course_id: courseId,
          is_hold: !isHold,  // Toggle the current hold status
        }, {
          headers: { Authorization: `Bearer ${token}` },  // Include Bearer token for authentication
        });
    
        notification.success({ message: isHold ? 'Unheld successfully' : 'Held successfully' });
    
        // Update the hold status in the frontend state
        setHoldSelections((prevState) => ({
          ...prevState,
          [`${studentId}-${courseId}`]: !isHold, // Toggle the current hold status in the state
        }));
    
      } catch (error) {
        console.error('Error updating hold status:', error);
        notification.error({ message: 'Failed to update hold status' });
      }
    };
    
    // Call this function in `useEffect` to fetch the data when the component loads
    useEffect(() => {
      data.forEach((record) => {
        fetchHoldStatus(record.studentid, record.course_id);
      });
    }, [data]); // Ensure this runs when `data` changes (e.g., on component mount)
    

  const csvData = filteredData.map(item => ({
    schoolid: item.schoolid,
    student: `${item.firstnamethai} ${item.lastnamethai}`,
    nationalid: item.nationalid,
    course: item.inventory_item_name,
    paid_amount: parseFloat(item.total_paid_amount).toFixed(2),
    test_course: item.test_course_name || '',
    test_result: item.pass_status === 'Pass' ? t('Pass_test') : t('no'),
    group_info: graduationRecords[item.inventory_item_name]
      ? `${graduationRecords[item.inventory_item_name].group}, ${moment(graduationRecords[item.inventory_item_name].start_date).format('DD-MM-YYYY')} - ${moment(graduationRecords[item.inventory_item_name].end_date).format('DD-MM-YYYY')}`
      : t('No Graduation Info'),
  }));

  return (
    <div className="students-invoice-page">
      <h1>{t('Students with Invoices and Passed Courses')}</h1>
      
      <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
        <Search
          placeholder={t('searchByNameOrNationalID')}
          enterButton
          onSearch={handleSearch} // Use the new search handler
          style={{ width: 300 }}
        />
        <Button style={{ marginLeft: '10px' }}>
          <CSVLink data={csvData} filename="graduate_students.csv">
            <FontAwesomeIcon icon={faFileExcel} /> {t('exportToExcel')}
          </CSVLink>
        </Button>
      </div>

      {loading ? (
        <Spin size="large" />
      ) : (
        <Table
          dataSource={filteredData}
          columns={columns}
          rowKey={(record, index) => `${record.studentid}-${record.course_id}-${index}`}
          pagination={{
            pageSize: 50,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      

      )}
    </div>
  );
};

export default withRoleAccess(GraduateList, ['admin', 'superadmin', 'teacher']);