import React, { useState, useEffect, useCallback, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { CSVLink } from 'react-csv';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { Table, message, Modal, Button } from 'antd';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import thLocale from '@fullcalendar/core/locales/th';
import './Classes.css';
import { DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import TaskPopup from './TaskPopup';
import { createTask, updateTask, deleteTask } from './apiService';
import axios from 'axios';



function AllClasses() {
  const { i18n, t } = useTranslation();
  const [teacherEvents, setTeacherEvents] = useState([]);
  const [eventsData, setEventsData] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isTaskPopupOpen, setIsTaskPopupOpen] = useState(false);
  const [currentTask, setCurrentTask] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const calendarRef = useRef(null);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [selectedMonthYear, setSelectedMonthYear] = useState(null);

  const API_URL = process.env.REACT_APP_API_URL;

  const fetchTeachers = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/teachers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const teachersWithColors = response.data.map((teacher) => ({
        ...teacher,
        color: teacher.color || '#000000',
      }));

      setTeachers(teachersWithColors);
    } catch (error) {
      console.error('Error fetching teachers:', error);
      setError('Failed to fetch teachers');
    }
  }, [API_URL]);

  const fetchCombinedEvents = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/events/fullevent`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      // Log the raw response data to see if there are any issues
      console.log('Fetched events data:', response.data);
  
      if (Array.isArray(response.data) && response.data.length === 0) {
        console.log('API returned an empty array. Setting eventsData to an empty array.');
        setEventsData([]);
        setTeacherEvents([]);
        setLoading(false);
        return;
      }
  
      const eventsWithColors = response.data.map((event) => {
        const teacher = teachers.find((t) => t.userid === event.shared_teacherid);
  
        // Log each event to make sure the fields are properly populated
        console.log('Event with teacher info:', event, teacher);
  
        return {
          // Add proper mapping logic for the event fields
          ...event,
          backgroundColor: teacher ? teacher.color : '#000000',
        };
      });
  
      setEventsData(eventsWithColors);
      setTeacherEvents(eventsWithColors);
    } catch (error) {
      console.error('Error fetching events:', error);
      setError('Failed to fetch events');
    } finally {
      setLoading(false);
    }
  }, [API_URL, teachers]);
  
  
  useEffect(() => {
    fetchTeachers();
  }, [fetchTeachers]);

  useEffect(() => {
    if (teachers.length > 0) {
      fetchCombinedEvents();
    }
  }, [teachers, fetchCombinedEvents]);

  useEffect(() => {
    if (selectedTeachers.length > 0) {
      const filteredEvents = eventsData.filter(event => selectedTeachers.includes(event.shared_teacherid));
      setTeacherEvents(filteredEvents);
    } else {
      setTeacherEvents(eventsData);
    }
  }, [selectedTeachers, eventsData]);

  const handleTeacherChange = (teacherId) => {
    setSelectedTeachers((prevSelectedTeachers) =>
      prevSelectedTeachers.includes(teacherId)
        ? prevSelectedTeachers.filter((t) => t !== teacherId)
        : [...prevSelectedTeachers, teacherId]
    );
  };

  const handleDateSelect = (selectInfo) => {
    setSelectedDate(selectInfo);
    setCurrentTask(null);
    setIsTaskPopupOpen(true);
  };

  const handleEventClick = (clickInfo) => {
    const taskToEdit = {
      id: clickInfo.event.id,
      title: clickInfo.event.title,
      start: clickInfo.event.start,
      end: clickInfo.event.end,
      description: clickInfo.event.extendedProps.description,
      shared_teacherid: clickInfo.event.extendedProps.shared_teacherid
    };
    setCurrentTask(taskToEdit);
    setIsTaskPopupOpen(true);
  };

  const handleEventChange = (info) => {
    Modal.confirm({
      title: t('Confirm change'),
      content: t('Are you sure you want to change this event?'),
      onOk: async () => {
        try {
          const updatedEvent = {
            id: info.event.id,
            start: moment(info.event.start).toISOString(),
            end: moment(info.event.end).toISOString(),
            title: info.event.title,
            description: info.event.extendedProps.description,
            shared_teacherid: info.event.extendedProps.shared_teacherid,
          };
  
          await updateTask(updatedEvent.id, updatedEvent); // Await the task update
          await fetchCombinedEvents(); // Re-fetch events after successful update
          message.success(t('Event updated successfully'));
        } catch (error) {
          console.error('Failed to update event:', error);
          message.error(t('Failed to update event'));
          info.revert(); // Revert the changes if the update fails
        }
      },
      onCancel: () => info.revert(),
    });
  };
  

  const handleTaskSave = async (taskDetails) => {
    try {
      if (taskDetails) {
        // Only attempt to save if taskDetails is not undefined
        if (currentTask) {
          await updateTask(currentTask.id, taskDetails);
        } else {
          await createTask(taskDetails);
        }

        await Promise.all([fetchCombinedEvents(), fetchTeachers()]);
  
        setIsTaskPopupOpen(false);
        message.success(t('Event saved successfully'));
      } else {
        console.warn('Task details are undefined, skipping save.');
      }
    } catch (error) {
      console.error('Error saving event:', error);
      console.error('Error details:', error.response?.data || error.message);
      message.error(t('Failed to save event'));
    }
  };
  
  
  const handleTaskDelete = async (taskId) => {
    try {
      await deleteTask(taskId);
  
      await Promise.all([fetchCombinedEvents(), fetchTeachers()]);
  
      setIsTaskPopupOpen(false);
      message.success(t('Event deleted successfully'));
    } catch (error) {
      console.error('Error deleting event:', error);
      console.error('Error details:', error.response?.data || error.message);
      message.error(t('Failed to delete event'));
    }
  };

  const handleMonthYearChange = (date) => {
    setSelectedMonthYear(date);

    if (date) {
      const startOfMonth = moment(date).startOf('month').toDate();
      const endOfMonth = moment(date).endOf('month').toDate();

      const filtered = teacherEvents.filter(event => {
        const eventDate = moment(event.start).toDate();
        return eventDate >= startOfMonth && eventDate <= endOfMonth;
      });

      setFilteredEvents(filtered);
    } else {
      setFilteredEvents(teacherEvents);
    }
  };

  const dateFormat = i18n.language === 'th' ? 'MM/YYYY' : 'MMMM YYYY'; 

  const columns = [
    {
      title: t('classid'),
      dataIndex: 'eventid',
      key: 'eventid',
    },
    {
      title: t('date'),
      dataIndex: 'start', 
      key: 'date',
      render: (start) => {
        const date = new Date(start);
        return date.toLocaleDateString(i18n.language, {
          day: '2-digit',
          month: 'long',
          year: 'numeric',
        });
      },
      sorter: (a, b) => new Date(a.start) - new Date(b.start),
    },
    {
      title: t('time'),
      key: 'time',
      render: (record) => {
        const startTime = new Date(record.start).toLocaleTimeString(i18n.language, {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        });
        const endTime = new Date(record.end).toLocaleTimeString(i18n.language, {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        });
        return `${startTime} - ${endTime}`; 
      },
      sorter: (a, b) => new Date(a.start) - new Date(b.start),
    },
    {
      title: t('teacher'),
      dataIndex: 'teacherName',
      key: 'teacherName',
      render: (text, record) => {
        const teacher = teachers.find(t => t.userid === record.shared_teacherid);
        return teacher ? `${teacher.firstname} ${teacher.lastname}` : 'Unknown';
      },
    },
    {
      title: t('subject'),
      dataIndex: 'event_title',
      key: 'event_title',
    },
    {
      title: t('description'),
      dataIndex: 'event_description',
      key: 'event_description',
    },
  ];

  const csvHeaders = [
    { label: "Class ID", key: "id" },
    { label: "Date", key: "start" },
    { label: "Time", key: "time" },
    { label: "Teacher", key: "teacherName" },
    { label: "Subject", key: "title" },
    { label: "Description", key: "description" },
  ];

  const csvData = (filteredEvents.length > 0 ? filteredEvents : teacherEvents).map(event => {
    const teacher = teachers.find(t => t.userid === event.shared_teacherid);
    
    return {
      id: event.eventid,
      start: new Date(event.start).toLocaleDateString(),  // Date of the event
      time: `${new Date(event.start).toLocaleTimeString()} - ${new Date(event.end).toLocaleTimeString()}`,  // Start and end times
      teacherName: teacher ? `${teacher.firstname} ${teacher.lastname}` : 'Unknown',  // Teacher name
      title: event.event_title,  // Event title
      description: event.event_description,  // Event description
    };
  });

  if (loading) return <p>{t('Loading...')}</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="class-container">
      <Sidebar />
      <Header />

      <div className="filter-container-allclass">
  {/* Teacher filter */}
  {teachers.map((teacher) => (
  <div key={teacher.userid} className="checkbox-container">
    <label>
      <span
        className="color-box"
        style={{
          display: 'inline-block',
          width: '15px',
          height: '15px',
          backgroundColor: teacher.color,
          marginRight: '10px',
          borderRadius: '10%',
        }}
      ></span>
      <input
        type="checkbox"
        value={teacher.userid}
        checked={selectedTeachers.includes(teacher.userid)}
        onChange={() => handleTeacherChange(teacher.userid)}
      />
      <span className="checkmark"></span>
      <span>{`${teacher.nickname} ${teacher.firstname}`}</span>
    </label>
  </div>
))}

</div>

      <div className="class-calendar-container">
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="timeGridWeek"
          allDaySlot={false}
          locale={i18n.language === 'th' ? thLocale : 'en'}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay',
          }}
          slotMinTime="07:00:00"
          slotMaxTime="22:00:00"
          events={teacherEvents}
          editable={true}
          selectable={true}
          select={handleDateSelect}
          eventClick={handleEventClick}
          eventDrop={handleEventChange}
          eventResize={handleEventChange}
          eventContent={(arg) => {
            const event = arg.event;
            const startTime = moment(event.start).format('HH:mm');
            const endTime = moment(event.end).format('HH:mm');
            
            // Access event details from `extendedProps`
            const eventid = event.extendedProps.eventid;
            const eventTitle = event.extendedProps.event_title; // Assuming this is the title field
            const teacher = teachers.find(t => t.userid === event.extendedProps.shared_teacherid);
          
            // Log to verify the fields
            console.log('Rendering event:', eventid, eventTitle, teacher);
          
            return {
              html: `
                <div style="background-color: ${event.backgroundColor}; padding: 2px; border-radius: 4px;">
                  <div>${startTime} - ${endTime}</div>
                  <div>${eventid || 'N/A'}</div>
                  <div style="white-space:pre-line">${eventTitle || 'No Title'}</div>
                  <div>${teacher ? teacher.firstname + ' ' + teacher.lastname : 'Unknown'}</div>
                </div>
              `,
            };
          }}
          
        />
        {!loading && eventsData.length === 0 && <p>{t('No events found.')}</p>} 
      </div>

          <div className="pagination-container">
          <div style={{ marginBottom: '20px' }}>
          {/* Month/Year Picker */}
          <DatePicker
            className="ant-datepicker"
            onChange={handleMonthYearChange}
            picker="month" // Dropdown to select Month/Year
            placeholder={t('Select Month and Year')}
            format={dateFormat} // Dynamically format based on the locale
            locale={i18n.language} // Ensure that the language is dynamically applied
          />
        </div>

    <Button style={{ marginBottom: '20px' }}>
      <CSVLink headers={csvHeaders} data={csvData} filename="class-data.csv">
        <FontAwesomeIcon icon={faFileExcel} /> {t('exportToExcel')}
      </CSVLink>
    </Button>
      </div>

      <Table
  columns={columns}
  dataSource={filteredEvents.length > 0 ? filteredEvents : teacherEvents}
  rowKey="id" // Make sure each event has a unique 'id' in the data source
  scroll={{ x: true }}
  pagination={{
    pageSize: 50,
    showSizeChanger: true,
    showQuickJumper: true,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
  }}
/>


      <TaskPopup
        isOpen={isTaskPopupOpen}
        onClose={() => setIsTaskPopupOpen(false)}
        onSave={handleTaskSave}
        onDelete={handleTaskDelete}
        task={currentTask}
        date={selectedDate}
        users={teachers}
      />
    </div>
  );
}

export default withRoleAccess(AllClasses, ['admin', 'superadmin']);