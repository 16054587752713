import React, { useState, useEffect } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { Table, Button, Modal, message, Space } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import '../Dashboard.css';
import SubjectModal from './SubjectModal';

function EditDeleteSubject() {
    const { t } = useTranslation();
    const [subjects, setSubjects] = useState([]);
    const [showSubjectModal, setShowSubjectModal] = useState(false);
    const [currentSubject, setCurrentSubject] = useState(null);
    const [loading, setLoading] = useState(true);
    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        fetchSubjects();
    }, []);

    const fetchSubjects = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${API_URL}/subjects`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setSubjects(response.data);
        } catch (error) {
            console.error('Error fetching subjects:', error);
            message.error(t('fetchSubjectsError'));
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = (subject) => {
        setCurrentSubject(subject);
        setShowSubjectModal(true);
    };

    const handleDelete = (subjectId) => {
        Modal.confirm({
            title: t('confirmDelete'),
            content: t('areYouSureToDelete'),
            okText: t('yes'),
            cancelText: t('no'),
            onOk: async () => {
                try {
                    const token = localStorage.getItem('token');
                    await axios.delete(`${API_URL}/subjects/${subjectId}`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                    setSubjects(prev => prev.filter(subject => subject.subjectid !== subjectId));
                    message.success(t('subjectDeleted'));
                } catch (error) {
                    console.error('Error deleting subject:', error);
                    message.error(t('deleteSubjectError'));
                }
            }
        });
    };

    const handleSaveSubject = async (subjectData) => {
        try {
            const token = localStorage.getItem('token');
            let response;
            if (currentSubject) {
                response = await axios.put(`${API_URL}/subjects/${currentSubject.subjectid}`, subjectData, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setSubjects(prev => prev.map(subject => subject.subjectid === response.data.subjectid ? response.data : subject));
                message.success(t('subjectUpdated'));
            } else {
                response = await axios.post(`${API_URL}/subjects`, subjectData, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setSubjects(prev => [...prev, response.data]);
                message.success(t('subjectCreated'));
            }
            setShowSubjectModal(false);
        } catch (error) {
            console.error('Error saving subject:', error);
            message.error(t('saveSubjectError'));
        }
    };

    const columns = [
        {
            title: t('code'),
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: t('group'),
            dataIndex: 'subjectgroup',
            key: 'subjectgroup',
        },
        {
            title: t('subjecttitle'),
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: t('description'),
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: t('actions'),
            key: 'actions',
            render: (_, record) => (
                <Space size="middle">
                    <Button icon={<EditOutlined />} onClick={() => handleEdit(record)} />
                    <Button icon={<DeleteOutlined />} onClick={() => handleDelete(record.subjectid)} danger />
                </Space>
            ),
        },
    ];

    return (
        <div className="subjecttable-container">
            <div className="admincontainer">
                <Button 
                    type="primary" 
                    icon={<PlusOutlined />} 
                    onClick={() => { setCurrentSubject(null); setShowSubjectModal(true); }}
                    style={{ marginBottom: 16 }}
                >
                    {t('createSubject')}
                </Button>

                <Table 
                    columns={columns} 
                    dataSource={subjects} 
                    rowKey="subjectid" 
                    loading={loading}
                />

                {showSubjectModal && (
                    <div className="subject-modal-backdrop">
                        <div className="subject-modal">
                            <div className="section">
                                <h2>{currentSubject ? t('editSubject') : t('createSubject')}</h2>
                                <button className="modal-close-button" onClick={() => setShowSubjectModal(false)}>×</button>
                            </div>
                            <div className="modal-body">
                                <SubjectModal 
                                    isOpen={showSubjectModal}
                                    onClose={() => setShowSubjectModal(false)}
                                    onSave={handleSaveSubject}
                                    subjectData={currentSubject}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default withRoleAccess(EditDeleteSubject, ['admin', 'superadmin']);