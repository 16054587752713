import React, { useState, useEffect } from 'react';
import { Table, Input, Button, Spin, notification, Tooltip, Card, Row, Col, Statistic } from 'antd';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import 'moment/locale/th';
import ReceiptModal from './ReceiptModal'; 
import { useTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const PaymentReportPage = () => {
  const { t, i18n } = useTranslation();
  const [payments, setPayments] = useState([]);
  const [filteredPayments, setFilteredPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState([null, null]);
  const [searchTerm, setSearchTerm] = useState('');
  const [documentNoSearch, setDocumentNoSearch] = useState('');
  const [invoiceNoSearch, setInvoiceNoSearch] = useState('');
  const [isReceiptModalOpen, setIsReceiptModalOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [summaryStats, setSummaryStats] = useState({
    yearToDate: 0,
    monthToDate: 0,
    today: 0
  });
  const API_URL = process.env.REACT_APP_API_URL;

  const formatDateTimeWithLocale = (dateString) => {
    const date = moment(dateString).toDate(); 
    if (isNaN(date.getTime())) {
      return 'Invalid Date'; 
    }
    const options = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false, 
    };
    return date.toLocaleDateString(i18n.language, options).toUpperCase();
  };
  const calculateSummaryStats = (payments) => {
    const now = moment();
    const startOfYear = moment().startOf('year');
    const startOfMonth = moment().startOf('month');
    const startOfDay = moment().startOf('day');
  
    const stats = payments.reduce((acc, payment) => {
      const paymentDate = moment(payment.created_at);
      const amount = Number(payment.payment_amount);
  
      if (paymentDate.isSameOrAfter(startOfYear)) {
        acc.yearToDate += amount;
      }
      if (paymentDate.isSameOrAfter(startOfMonth)) {
        acc.monthToDate += amount;
      }
      if (paymentDate.isSameOrAfter(startOfDay)) {
        acc.today += amount;
      }
  
      return acc;
    }, { yearToDate: 0, monthToDate: 0, today: 0 });
  
    setSummaryStats(stats);
  };

  const formatPrice = (price) => {
    const formattedPrice = Number(price);
    return isNaN(formattedPrice)
      ? '0.00'
      : new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(formattedPrice);
  };

  useEffect(() => {
    fetchPayments();
  }, []);

  const fetchPayments = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      
      const response = await axios.get(`${API_URL}/payment/income-reports`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      setPayments(response.data);
      setFilteredPayments(response.data);
      calculateSummaryStats(response.data); // Calculate summary stats
      setLoading(false);
    } catch (error) {
      console.error('Error fetching payment report:', error);
      notification.error({
        message: t('error'),
        description: t('fetch_payment_report_error'),
      });
      setLoading(false);
    }
  };

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
    const [start, end] = dates || [];
    if (!start || !end) {
      setFilteredPayments(payments);
      return;
    }

    const filtered = payments.filter(payment => {
      const paymentDate = moment(payment.created_at);
      return paymentDate.isBetween(moment(start).startOf('day'), moment(end).endOf('day'), null, '[]');
    });

    setFilteredPayments(filtered);
  };

  const handleSearch = (value, type) => {
    if (type === 'nameOrId') {
      setSearchTerm(value.toLowerCase());
    } else if (type === 'documentNo') {
      setDocumentNoSearch(value.toLowerCase());
    } else if (type === 'invoiceNo') {
      setInvoiceNoSearch(value.toLowerCase());
    }

    const filtered = payments.filter(payment =>
      (type === 'nameOrId' && (
        (payment.student_name && payment.student_name.toLowerCase().includes(value.toLowerCase())) ||
        (payment.nationalid && payment.nationalid.toString().includes(value))
      )) ||
      (type === 'documentNo' && payment.document_no.toLowerCase().includes(value.toLowerCase())) ||
      (type === 'invoiceNo' && payment.invoice_no.toLowerCase().includes(value.toLowerCase()))
    );

    setFilteredPayments(filtered);
  };


  const handleReceiptModalClose = () => {
    setIsReceiptModalOpen(false);
    setSelectedTransaction(null);
  };

  const openReceiptModal = (transaction) => {
    setSelectedTransaction(transaction);
    setIsReceiptModalOpen(true);
  };

  const csvData = filteredPayments.map(payment => ({
    student_id: payment.schoolid,
    nationalid: payment.nationalid,
    student_name: payment.student_name,
    document_no: payment.document_no,
    items: payment.items 
      ? payment.items.map(item => item.item_name).join(', ') 
      : t('No items'), 
    final_amount: payment.final_amount,
    paid_amount: payment.payment_amount,
    remaining_balance: payment.remaining_amount,
    invoice_no: payment.invoice_no,
    payment_method: payment.payment_method,
    memo: payment.memo,
    created_by: payment.nickname,
    created_at: formatDateTimeWithLocale(payment.created_at),
  }));
  
  

  const columns = [
    {
      title: t('document_no'),
      dataIndex: 'document_no',
      key: 'document_no',
    },
    {
      title: t('nationalid'),
      dataIndex: 'nationalid',
      key: 'nationalid',
    },
    {
      title: t('student_id'),
      dataIndex: 'schoolid',
      key: 'schoolid',
    },
    {
      title: t('student_name'),
      dataIndex: 'student_name',
      key: 'student_name',
      ellipsis: true,
    },
  
    {
      title: t('items'),
      key: 'items',
      render: (record) => {
        if (!record.items || record.items.length === 0) {
          return t('No items');
        }
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {record.items.map((item, index) => (
              <Tooltip key={index} title={item.item_name}>
                <div
                  style={{
                    padding: '4px 0',
                    borderBottom: index < record.items.length - 1 ? '1px solid #f0f0f0' : 'none',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '100%',
                  }}
                >
                  {item.item_name}
                </div>
              </Tooltip>
            ))}
          </div>
        );
      },
    },
    {
      title: t('full_amount'),
      dataIndex: 'final_amount',
      key: 'final_amount',
      render: (amount) => (
        <span style={{ color: 'grey', fontWeight: 'bold' }}>
          {formatPrice(amount)}
        </span>
      ),
    },
    {
      title: t('paid_amount'),
      dataIndex: 'payment_amount',
      key: 'payment_amount',
      render: (amount) => (
        <span style={{ color: 'green', fontWeight: 'bold' }}>
          {formatPrice(amount)}
        </span>
      ),
    },
    {
      title: t('remaining_balance'),
      dataIndex: 'remaining_amount',
      key: 'remaining_amount',
      render: (amount) => (
        <span style={{ color: 'red', fontWeight: 'bold' }}>
          {formatPrice(amount)}
        </span>
      ),
    },
    {
      title: t('receiptNo'),
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      ellipsis: true,
    },
    {
      title: t('payment_method'),
      dataIndex: 'payment_method',
      key: 'payment_method',
      ellipsis: true,
      render: (text) => {
        switch (text) {
          case 'cash':
            return t('cash');
          case 'qr code':
            return t('qr_code');
          case 'credit card':
            return t('credit_card');
          case 'transfer':
            return t('transfer');
          default:
            return text; 
        }
      },
    },
    {
      title: t('memo'),
      dataIndex: 'memo',
      key: 'memo',
    },
    {
      title: t('created_by'),
      dataIndex: 'nickname',
      key: 'nickname',
    },
    {
      title: t('date'),
      dataIndex: 'created_at',
      key: 'created_at',
      ellipsis: true,
      render: (date) => formatDateTimeWithLocale(date),
    },
    {
      title: t('receipt'),
      key: 'actions',
      render: (_, record) => (
        <>
          <Button type="primary" onClick={() => openReceiptModal(record)} style={{ marginRight: 8 }}>
            {t('receipt')}
          </Button>
          {record.payment_file && (
           <Button type="default" onClick={() => {
            const filename = record.payment_file.split('/').pop();
            window.open(`${API_URL}/payment/download/${filename}`, '_blank');
          }}>
            {t('bank_slip')}
          </Button>
          )}
        </>
      ),
    },
  ];
  
  
  return (
    <div>
      <h1>{t('รายรับ')}</h1>
      <div style={{ marginBottom: 20 }}>
      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col span={8}>
          <Card style={{ backgroundColor: '#E6F7FF', borderColor: '#1890FF' }}>
            <Statistic
              title={t('year_to_date_income')}
              value={summaryStats.yearToDate}
              precision={2}
              prefix="฿"
              valueStyle={{ color: '#0050B3' }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card style={{ backgroundColor: '#F6FFED', borderColor: '#52C41A' }}>
            <Statistic
              title={t('month_to_date_income')}
              value={summaryStats.monthToDate}
              precision={2}
              prefix="฿"
              valueStyle={{ color: '#389E0D' }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card style={{ backgroundColor: '#FFF7E6', borderColor: '#FA8C16' }}>
            <Statistic
              title={t('today_income')}
              value={summaryStats.today}
              precision={2}
              prefix="฿"
              valueStyle={{ color: '#D46B08' }}
            />
          </Card>
        </Col>
      </Row>
      <DatePicker
          selectsRange={true}
          startDate={dateRange[0]}
          endDate={dateRange[1]}
          onChange={handleDateRangeChange}
          dateFormat="dd-MM-yyyy"
          placeholderText={t('Select Date Range')}
        />
        <Input.Search
          placeholder={t('searchByNameOrNationalID')}
          enterButton
          onSearch={(value) => handleSearch(value, 'nameOrId')}
          style={{ width: 250, marginLeft: '10px', marginRight: '10px' }}
        />
        <Input.Search
          placeholder={t('searchByDocumentNo')}
          enterButton
          onSearch={(value) => handleSearch(value, 'documentNo')}
          style={{ width: 250, marginRight: 10 }}
        />
        <Input.Search
          placeholder={t('searchByRecieptNo')}
          enterButton
          onSearch={(value) => handleSearch(value, 'invoiceNo')}
          style={{ width: 250, marginRight: 10 }}
        />

        <CSVLink data={csvData} filename="payment_report.csv">
          <Button style={{ marginLeft: 10 }}>
            <FontAwesomeIcon icon={faFileExcel}/>
            {t('exportToExcel')}
          </Button>
        </CSVLink>
      </div>

      {loading ? (
        <Spin tip={t('loading')} />
      ) : (
        <Table columns={columns} dataSource={filteredPayments} rowKey="payment_id" 
        scroll={{ x: true }}
        pagination={{
          pageSize: 50,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        }}
        />
      )}

    <ReceiptModal
      show={isReceiptModalOpen}
      onClose={handleReceiptModalClose}
      registration={selectedTransaction} 
    />
    </div>
  );
};

export default withRoleAccess(PaymentReportPage, ['superadmin', 'admin']);
