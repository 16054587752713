import React, { useState, useEffect } from 'react';
import { Table, Button, message, Spin, Input, DatePicker, Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import moment from 'moment';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv';

const { Search } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;

const PaidPassStudent = () => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [certificatesLoading, setCertificatesLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [issuedCertificates, setIssuedCertificates] = useState([]);  // To store issued certificates
  const API_URL = process.env.REACT_APP_API_URL;

  const formatPrice = (price) => {
    const formattedPrice = Number(price);
    return isNaN(formattedPrice)
      ? '0.00'
      : new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(formattedPrice);
  };

  // Fetch student data (separate from certificate API)
  useEffect(() => {
    const fetchStudentData = async () => {
      setLoading(true);
      const token = localStorage.getItem('token');
      try {
        const studentsResponse = await axios.get(`${API_URL}/graduate-student/fullypaid_passtest`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        // Sort the students' data by payment date
        const sortedData = studentsResponse.data.sort((a, b) => new Date(b.date_paid) - new Date(a.date_paid));
        setData(sortedData);
        setFilteredData(sortedData);
      } catch (error) {
        console.error('Error fetching student data:', error);
        message.error(t('fetchDataError'));
      } finally {
        setLoading(false);
      }
    };
    fetchStudentData();
  }, [API_URL, t]);

  // Fetch issued certificates (separate from student data API)
  useEffect(() => {
    const fetchCertificates = async () => {
      setCertificatesLoading(true);
      const token = localStorage.getItem('token');
      try {
        const certificatesResponse = await axios.get(`${API_URL}/graduate-student/issued-certificates`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        // Store issued certificates
        setIssuedCertificates(certificatesResponse.data.certificates);
      } catch (error) {
        console.error('Error fetching certificates:', error);
        message.error(t('fetchDataError'));
      } finally {
        setCertificatesLoading(false);
      }
    };
    fetchCertificates();
  }, [API_URL, t]);

 
  const applyFilters = (search, dateRange, status) => {
    let filtered = data;
  
    // Apply search filter
    if (search) {
      filtered = filtered.filter(
        invoice =>
          (invoice.firstnamethai && invoice.firstnamethai.toLowerCase().includes(search.toLowerCase())) ||
          (invoice.lastnamethai && invoice.lastnamethai.toLowerCase().includes(search.toLowerCase())) ||
          (invoice.nationalid && invoice.nationalid.includes(search))
      );
    }
  
    if (dateRange && dateRange[0] && dateRange[1]) {
      const startDate = moment(dateRange[0]).startOf('day');
      const endDate = moment(dateRange[1]).endOf('day');
      console.log('Date range:', startDate.format('YYYY-MM-DD'), 'to', endDate.format('YYYY-MM-DD'));
      filtered = filtered.filter(invoice => {
        const paidDate = moment(invoice.date_paid);
        console.log('Comparing:', paidDate.format('YYYY-MM-DD'), 'in range:', paidDate.isBetween(startDate, endDate, null, '[]'));
        return paidDate.isBetween(startDate, endDate, null, '[]');
      });
      console.log('Filtered data length after date filter:', filtered.length);
    }
  
    // Apply pass/fail status filter
    if (status) {
      filtered = filtered.filter(invoice => 
        status === 'Pass' ? invoice.pass_status === 'Pass' : invoice.pass_status !== 'Pass'
      );
    }
  
    setFilteredData(filtered);
  };
  const handleSearch = (value) => {
    setSearchTerm(value);
    applyFilters(value, selectedDateRange, selectedStatus);
  };
  
  // Update the handleDateRangeChange function
  const handleDateRangeChange = (dates) => {
    if (dates) {
      setSelectedDateRange([dates[0].toDate(), dates[1].toDate()]);
      applyFilters(searchTerm, [dates[0].toDate(), dates[1].toDate()], selectedStatus);
    } else {
      setSelectedDateRange(null);
      applyFilters(searchTerm, null, selectedStatus);
    }
  };
  const handleStatusChange = (value) => {
    setSelectedStatus(value);
    applyFilters(searchTerm, selectedDateRange, value);
  };

  const handleIssueCertificate = async (record) => {
    const token = localStorage.getItem('token');
    try {
      await axios.post(
        `${API_URL}/graduate-student/issue-certificate`,
        {
          student_id: record.studentid,
          schoolid: record.schoolid,
          inventory_id: record.inventory_id,
          document_no: record.document_no,
          course_id: record.course_id
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      message.success(t('Certificate issued successfully.'));
    } catch (error) {
      console.error('Error issuing certificate:', error);
      message.error(t('Error issuing certificate.'));
    }
  };

  const isCertificateIssued = (studentId, inventoryId) => {
    return issuedCertificates.find(cert => cert.student_id === studentId && cert.inventory_id === inventoryId);
  };

  const columns = [
    {
      title: t('schoolid'),
      dataIndex: 'schoolid',
      key: 'schoolid',
    },
    {
      title: t('document_no'),
      dataIndex: 'document_no',
      key: 'document_no',
    },
    {
      title: t('payment_date'),
      dataIndex: 'date_paid',
      key: 'date_paid',
      ellipsis: true,
      render: (date) => {
        if (!date) return 'N/A';
        return new Intl.DateTimeFormat(i18n.language, {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
        }).format(new Date(date));
      }
    },
    {
      title: t('student'),
      key: 'studentNameThai',
      render: (text, record) => `${record.firstnamethai || ''} ${record.lastnamethai || ''}`,
      ellipsis: true,
    },
    {
        title: t('Student'),
        key: 'studentNameEnglish',
        render: (text, record) => `${record.firstnameenglish || ''} ${record.lastnameenglish || ''}`,
        ellipsis: true,
      },
    {
      title: t('nationalid'),
      dataIndex: 'nationalid',
      key: 'nationalid',
    },
    {
      title: t('items'),
      dataIndex: 'inventory_item_name',
      key: 'inventory_item_name',
      ellipsis: true,
    },
    {
      title: t('Already Paid'),
      dataIndex: 'total_paid_amount',
      key: 'total_paid_amount',
      render: (amount) => formatPrice(amount),
    },
    {
      title: t('Test Date'),
      dataIndex: 'test_date',
      key: 'test_date',
      ellipsis: true,
      render: (date) => {
        if (!date) return 'N/A';
        return new Intl.DateTimeFormat(i18n.language, {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
        }).format(new Date(date));
      }
    },
    {
      title: t('Test Type'),
      dataIndex: 'test_course_name',
      key: 'test_course_name',
      ellipsis: true,
    },
    {
      title: t('Test Result'),
      dataIndex: 'pass_status',
      key: 'pass_status',
      render: (status) => {
        if (status === 'Pass') {
          return <span style={{ color: 'green', fontWeight: 'bold' }}>{t('Pass')}</span>;
        } else {
          return <span style={{ color: 'red', fontWeight: 'bold' }}>{t('Fail')}</span>;
        }
      }
    },
      {
        title: t('Certificate Issued'),
        key: 'certificate_issued',
        render: (text, record) => {
          const certificate = isCertificateIssued(record.studentid, record.inventory_id);
          const isDisabled = record.pass_status !== 'Pass';
          
          if (certificate) {
            return <span style={{ color: 'green', fontWeight: 'bold' }}>{t('Printed')}</span>;
          }
          
          return (
            <Button 
              onClick={() => handleIssueCertificate(record)} 
              disabled={isDisabled}
              type={isDisabled ? 'default' : 'primary'}
            >
              {t('Issue Certificate')}
            </Button>
          );
        }
      },
      {
        title: t('Certificate Details'),
        key: 'certificate_details',
        render: (text, record) => {
          const certificate = isCertificateIssued(record.studentid, record.inventory_id);
          
          if (certificate) {
            return (
              <span>
                {moment(certificate.timestamp).format('DD/MM/YYYY HH:mm')} {t('by')} {certificate.created_by_nickname}
              </span>
            );
          }
          
          return '-';
        }
      }    
  ];

  const csvData = filteredData.map((invoice) => ({
    schoolid: invoice.schoolid,
    document_no: invoice.document_no,
    date_paid: moment(invoice.date_paid).format('DD/MM/YYYY'),
    studentNameThai: `${invoice.firstnamethai} ${invoice.lastnamethai}`,
    studentNameEnglish: `${invoice.firstnameenglish} ${invoice.lastnameenglish}`,
    nationalid: invoice.nationalid,
    total_paid_amount: formatPrice(invoice.total_paid_amount),
    test_date: invoice.test_date ? moment(invoice.test_date).format('DD/MM/YYYY') : 'N/A',
    course_name: invoice.test_course_name,
    pass_fail: invoice.pass_status === 'Pass' ? t('Pass') : t('Fail'),
  }));

  return (
    <div style={{ padding: '20px' }}>
      <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>

        {/* Search bar for name or national ID */}
        <Search
          placeholder={t('searchByNameOrNationalID')}
          onSearch={handleSearch}
          enterButton
          style={{ maxWidth: '300px' }}
        />

<RangePicker
  onChange={handleDateRangeChange}
  style={{ marginRight: '10px' }}
  format="DD/MM/YYYY"
  value={selectedDateRange ? [moment(selectedDateRange[0]), moment(selectedDateRange[1])] : null}
/>

        {/* Dropdown for Pass/Fail */}
        <Select
          placeholder={t('Filter by Result')}
          onChange={handleStatusChange}
          style={{ width: 150 }}
          allowClear
        >
          <Option value="Pass">{t('Pass')}</Option>
          <Option value="Fail">{t('Fail')}</Option>
        </Select>

        {/* Export to CSV button */}
        <Button>
          <CSVLink data={csvData} filename="fully_paid_invoices.csv">
            <FontAwesomeIcon icon={faFileExcel} /> {t('exportToExcel')}
          </CSVLink>
        </Button>          
      </div>

      <Spin spinning={loading || certificatesLoading}>
        <Table 
          columns={columns} 
          dataSource={filteredData} 
          rowKey={(record) => `${record.studentid}-${record.inventory_id}-${record.document_no}`}  // Ensure uniqueness
          scroll={{ x: true }}
          pagination={{
              pageSize: 50,
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      </Spin>
    </div>
  );
};

export default withRoleAccess(PaidPassStudent, ['superadmin']);
