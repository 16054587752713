import React, { useRef, useCallback} from 'react';
import { Modal, Button, message } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import QRCode from 'qrcode.react';  // Import the QRCode component
import './Test.css';

const courseNameTranslations = {
  "หลักสูตรนวดแผนไทยเพื่อสุขภาพ": "Thai Massage for Health (150 Hours)",
  "หลักสูตรน้ำมันหอมระเหย": "Aromatherapy Massage (150 Hours)",
  "หลักสูตรนวดเท้าเพื่อสุขภาพ": "Foot massage for health (60 Hours)",
  "หลักสูตรสปาหน้า": "Facial Spa",
  "หลักสูตรสปาตัว": "Body Spa",
  "หลักสูตรนวดหินร้อน": "Hot Stone",
  "หลักสูตรนวดประคบร้อน": "Hot Herbal Ball",
  "หลักสูตรแก้อาการเบื้องต้น": "Acupressure Technique Therapy",
  "หลักสูตรนวดสวีดิช": "Swedish Massage",
  "หลักสูตรนวดแบมบู": "Bamboo Massage"
};

const translateCourseName = (courseName) => {
  return courseNameTranslations[courseName] || courseName; // Return translated name or original if not found
};

const CertificateModal = ({ visible, onCancel, studentNameEnglish, courseName, hours, uniqueId }) => {
    const printRef = useRef();
    const DEFAULT_STUDENT_NAME = 'Student Name';
    const DEFAULT_COURSE_NAME = 'Thai Massage';
    const DEFAULT_HOURS = 150;
    const UNIQUE_ID_NOT_FOUND = 'uniqueid'; // Example default ID when uniqueId is not available

    const today = new Date();
    const formattedDate = today.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });

    const downloadAsPdf = useCallback(() => {
      const input = printRef.current;
      const button = document.querySelector('.no-print'); // Get the button element

      if (!input) {
        message.error('Content not ready. Please try again.');
        return;
      }

      button.style.display = 'none'; // Temporarily hide the button

      html2canvas(input, { scale: 2 })
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'px',
            format: [canvas.width, canvas.height],
          });

          pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
          pdf.save('Certificate.pdf');
          message.success('Certificate downloaded successfully as PDF');
        })
        .catch((error) => {
          console.error('PDF generation error:', error);
          message.error('Failed to download as PDF. Please try again.');
        });
    }, []);

    return (
      <Modal visible={visible} onCancel={onCancel} footer={null} width={800}>
        <div ref={printRef} className="certificate-layout">
          <div className="print-button-container no-print">
            <Button onClick={downloadAsPdf} icon={<DownloadOutlined />} />
          </div>
          <div className="certificate-header">
            <img src="/images/logo.png" alt="School Logo" className="certificate-logo" />
            <h2>Pani Spa Thai Massage School Pattaya</h2>
            <h3>Certificate</h3>
          </div>

          <div className="certificate-body">
            <p>This letter is to certify that</p>
            <h2 className="student-name">{studentNameEnglish || DEFAULT_STUDENT_NAME}</h2>
            <p>has successfully completed the training program on</p>
            <h2 className="course-name">{translateCourseName(courseName) || DEFAULT_COURSE_NAME}</h2>
            <p>Given on <span className="date">{formattedDate}</span></p>
          </div>

          <div className="certificate-footer">
            <div className="signature-wrapper">
              <div className="signature-section">
                <p>..........................................................</p>
              </div>
              <div className="signature-section-below">
                <p>({studentNameEnglish || DEFAULT_STUDENT_NAME})</p>
              </div>
            </div>

            <div className="instructure-signature">
              <img src="/images/signature.png" alt="Signature" className="instructure-signature-image" />
            </div>
            <div className="stamp-section">
              <img src="/images/stamp.png" alt="School Stamp" className="stamp-image" />
            </div>

            <div className="instructor-section">
              <p>Miss Kanok-on Leenurat</p>
              <p>Instructor of the Pani Thai Massage School Pattaya</p>
            </div>
          </div>

          {/* Add the unique ID and QR code at the bottom */}
          <div className="unique-id-section">
            {uniqueId && (
              <div className="qr-code">
                <QRCode value={encodeURIComponent(uniqueId)} size={40} level="H" />
              </div>
            )}
            <p>{uniqueId || UNIQUE_ID_NOT_FOUND}</p>
          </div>
        </div>
      </Modal>
    );
};

export default CertificateModal;
