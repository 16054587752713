import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, Row, Col, Typography, Divider } from 'antd';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';
import './PaymentPage.css'; 

const { Title, Text } = Typography;

const InvoiceModal = ({ show, onClose, registration }) => {
  const { t, i18n } = useTranslation();
  const componentRef = useRef();
  const [totalAmount, setTotalAmount] = useState(0);
  const [isPrinting, setIsPrinting] = useState(false);

  const formatNumber = (number) => {
    const num = typeof number === 'string' ? parseFloat(number) : number;

    if (isNaN(num) || num === null || num === undefined) {
      return '0.00';
    }
  
    return num.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'long', year: 'numeric' };
    return date.toLocaleDateString(i18n.language, options).toUpperCase();
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      setIsPrinting(true);
      return new Promise((resolve) => {
        setTimeout(resolve, 500);
      });
    },
    onAfterPrint: () => {
      setIsPrinting(false);
    },
  });

  useEffect(() => {
    if (show && registration) {
      const total = registration.items.reduce((acc, item) => acc + (item.price * item.quantity), 0);
      setTotalAmount(total);
    }
  }, [show, registration]);

  if (!registration) {
    return null;
  }

  const InvoiceContent = () => (
    <div className="invoice-content">
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <img src="/images/logo.png" alt="panilogo" className="invoice-logo" />
        </Col>
        <Col span={12} className="text-right">
          <Title level={4}>โรงเรียนปาณิสปา พัทยา</Title>
          <Text>17/1-2 หมู่ที่ 13 ตําบลหนองปรือ อําเภอบางละมุง จังหวัดชลบุรี20150</Text>
          <br />
          <Text>เลขที่ผู้เสียภาษี 1-3199-00038-11-3</Text>
          <br />
          <Text>โทร: 084-398-2888 | panithaimassageschool@gmail.com</Text>
          <br />
          <Text>https://www.panithaimassageschool.com/</Text>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[24, 24]}>
        <Col span={12}>
          <Title level={4}>{t('invoice')}</Title>
          <Text><strong>{t('transaction_date')}:</strong> {formatDate(registration.transaction_date)}</Text>
          <br />
          <Text><strong>{t('invoiceNo')}:</strong> {registration.document_no}</Text>
        </Col>
        <Col span={12}>
          <Title level={5}>{t('billTo')}</Title>
          <Text><strong>{t('name')}</strong> {registration.student_name}</Text>
          <br />
          <Text><strong>{t('nationalID')}</strong> {registration.nationalid || 'N/A'}</Text>
          <br />
          <Text><strong>{t('address')}</strong> {registration.address || 'N/A'}</Text>
        </Col>
      </Row>

      <Divider />

      <table className="invoice-table">
        <thead>
          <tr>
            <th>{t('number')}</th>
            <th>{t('item')}</th>
            <th>{t('quantity')}</th>
            <th>{t('unitPrice')}</th>
            <th>{t('amount')}</th>
          </tr>
        </thead>
        <tbody>
          {registration.items.map((item, index) => (
            <tr key={item.inventory_id}>
              <td>{index + 1}</td>
              <td>{item.item_name}</td>
              <td>{item.quantity}</td>
              <td>{formatNumber(item.price)}</td>
              <td>{formatNumber(item.price * item.quantity)}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <Row justify="end" className="invoice-summary">
        <Col span={8}>
          <Row justify="space-between">
            <Col><Text strong>{t('total_amount')}:</Text></Col>
            <Col><Text>{formatNumber(registration.total_amount)} บาท</Text></Col>
          </Row>
          <Row justify="space-between">
            <Col><Text strong>{t('discount')}:</Text></Col>
            <Col><Text>{formatNumber(registration.discount)} บาท</Text></Col>
          </Row>
          <Row justify="space-between">
            <Col><Text strong>{t('vat')}:</Text></Col>
            <Col><Text>{formatNumber(registration.vat)} บาท</Text></Col>
          </Row>
          <Divider />
          <Row justify="space-between">
            <Col><Text strong>{t('final_amount')}:</Text></Col>
            <Col><Text strong>{formatNumber(registration.final_amount)} บาท</Text></Col>
          </Row>
        </Col>
      </Row>
    </div>
  );

  return (
    <>
      <Modal
        visible={show}
        onCancel={onClose}
        footer={null}
        width={800}
        className="custom-invoice-modal"
      >
        <InvoiceContent />
        <div className="invoice-actions">
          <Button type="primary" onClick={handlePrint}>{t('printInvoice')}</Button>
        </div>
      </Modal>

      {/* Hidden printable content */}
      <div style={{ display: 'none' }}>
        <div ref={componentRef}>
          <InvoiceContent />
        </div>
      </div>
    </>
  );
};

export default InvoiceModal;