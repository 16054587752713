import React, { useState } from 'react';
import axios from 'axios';
import { Form, Input, Button, Select, notification } from 'antd';
import './InventoryPage.css';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;
const { Option } = Select;

const InventoryPage = () => {
    const { t } = useTranslation();
    const [newItem, setNewItem] = useState({
        product_id: '',
        item_name: '',
        item_description: '',
        category: '',
        price_per_unit: 0.0
    });

    const API_URL = process.env.REACT_APP_API_URL;

    // Function to handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewItem((prevItem) => ({
            ...prevItem,
            [name]: value
        }));
    };

    // Function to add a new inventory item
    const handleAddItem = async () => {
        try {
            const token = localStorage.getItem('token'); // Get token from localStorage
            await axios.post(`${API_URL}/inventory`, newItem, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the authorization token in headers
                }
            });

            notification.success({
                message: 'Success!',
                description: 'Inventory item added successfully.',
            });

            // Clear form inputs after successful addition
            setNewItem({
                product_id: '',
                item_name: '',
                item_description: '',
                category: '',
                price_per_unit: 0.0
            });

        } catch (error) {
            console.error('Error adding inventory item:', error);

            notification.error({
                message: 'Error',
                description: 'Failed to add inventory item. Please try again.',
            });
        }
    };

    return (
        <div className="inventory-page">
            <Sidebar />
            <Header />
            <div>
                <Form
                    layout="vertical"
                    onFinish={handleAddItem}
                    className="inventory-form"
                >
                    <Form.Item
                        label={t('Product ID')}
                        name="product_id"
                        rules={[{ required: true, message: t('Please enter Product ID') }]}
                    >
                        <Input
                            name="product_id"
                            value={newItem.product_id}
                            onChange={handleInputChange}
                            placeholder={t('Enter Product ID')}
                        />
                    </Form.Item>

                    <Form.Item
                        label={t('Item Name')}
                        name="item_name"
                        rules={[{ required: true, message: t('Please enter Item Name') }]}
                    >
                        <Input
                            name="item_name"
                            value={newItem.item_name}
                            onChange={handleInputChange}
                            placeholder={t('Enter Item Name')}
                        />
                    </Form.Item>

                    <Form.Item
                        label={t('Item Description')}
                        name="item_description"
                    >
                        <TextArea
                            name="item_description"
                            value={newItem.item_description}
                            onChange={handleInputChange}
                            placeholder={t('Enter Item Description')}
                            autoSize={{ minRows: 3, maxRows: 5 }}
                        />
                    </Form.Item>

                    <Form.Item
                        label={t('Category')}
                        name="category"
                        rules={[{ required: true, message: t('Please select a category') }]}
                    >
                        <Select
                            name="category"
                            value={newItem.category}
                            onChange={(value) => setNewItem({ ...newItem, category: value })}
                            placeholder={t('Select a category')}
                        >
                            <Option value="Book">{t('Book')}</Option>
                            <Option value="Uniform">{t('Uniform')}</Option>
                            <Option value="Exam Uniform">{t('Exam Uniform')}</Option>
                            <Option value="Certificates">{t('Certificates')}</Option>
                            <Option value="Others">{t('Others')}</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label={t('Price per Unit')}
                        name="price_per_unit"
                        rules={[{ required: true, message: t('Please enter Price per Unit') }]}
                    >
                        <Input
                            type="number"
                            step="0.01"
                            name="price_per_unit"
                            value={newItem.price_per_unit}
                            onChange={handleInputChange}
                            placeholder={t('Enter Price per Unit')}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            {t('Add Item')}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default withRoleAccess(InventoryPage, ['superadmin']);
