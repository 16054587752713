import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, Row, Col, Divider } from 'antd';
import './PaymentPage.css'; // Ensure this file contains the necessary styling
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';

const ReceiptModal = ({ show, onClose, registration }) => {
  const { t, i18n } = useTranslation();
  const componentRef = useRef();
  const [totalPaid, setTotalPaid] = useState(0);

  const formatNumber = (number) => {
    const num = typeof number === 'string' ? parseFloat(number) : number;

    if (isNaN(num) || num === null || num === undefined) {
      return '0.00';
    }
  
    return num.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'long', year: 'numeric' };
    return date.toLocaleDateString(i18n.language, options).toUpperCase();
  };

  const translatePaymentMethod = (method) => {
    switch (method) {
      case 'cash':
        return t('cash');
      case 'qr code':
        return t('qr_code');
      case 'credit card':
        return t('credit_card');
      case 'transfer':
        return t('transfer');
      default:
        return method; // Return the method as is if no match found
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (show && registration) {
      setTotalPaid(parseFloat(registration.payment_amount) || 0);
    }
  }, [show, registration]);

  if (!show || !registration) {
    return null;
  }

  // Join all item names into a single string
  const joinedItems = registration.items
    ? registration.items.map(item => item.item_name).join(', ')
    : '';

  return (
    <Modal
      visible={show}
      onCancel={onClose}
      footer={null}
      width={800}  // Adjust width based on your design
      className="custom-receipt-modal"
    >
      <div ref={componentRef} className="receipt-modal-content">
        {/* Header */}
        <Row gutter={[16, 16]} justify="space-between" className="receipt-header">
          <Col span={12}>
            <img src="/images/logo.png" alt="panilogo" className="invoice-logo" />
          </Col>
          <Col span={12} className="text-right">
            <p>โรงเรียนปาณิสปา พัทยา</p>
            <p>17/1-2 หมู่ที่ 13 ตําบลหนองปรือ อําเภอบางละมุง จังหวัดชลบุรี 20150</p>
            <p>เลขที่ผู้เสียภาษี 1-3199-00038-11-3</p>
            <p>โทร: 084-398-2888 | panithaimassageschool@gmail.com</p>
          </Col>
        </Row>

        <Divider />

        {/* Transaction and Invoice Info */}
        <Row gutter={[16, 16]} justify="space-between" className="invoice-details">
          <Col span={12}>
            <p>{t('student')}: {registration.student_name}</p>
            <p>{t('nationalID')}: {registration.nationalid || 'N/A'}</p>
          </Col>
          <Col span={12} className="text-right">
            <p>{t('transaction_date')}: {formatDate(registration.created_at)}</p>
            <p>{t('receiptNo')}: {registration.invoice_no}</p>
            <p>{t('paymentMethod')}: {translatePaymentMethod(registration.payment_method)}</p>
          </Col>
        </Row>

        <Divider />

        {/* Table to show Items, Paid Amount, Remaining Balance, and Document Number */}
        <table className="receipt-table">
          <thead>
            <tr>
              <th>{t('invoiceno')}</th>
              <th>{t('items')}</th>
              <th>{t('ยอดเต็ม')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{registration.document_no}</td>
              <td>{joinedItems}</td> 
              <td>{formatNumber(registration.final_amount)}</td>
            </tr>
          </tbody>
        </table>

        <Divider />

        {/* Summary */}
        <Row gutter={[16, 16]} justify="end" className="receipt-summary">
          <Col span={8}>
            <Row justify="space-between">
              <Col>{t('paidAmount')}:</Col>
              <Col>
                <span style={{ color: 'green', fontWeight: 'bold' }}>
                  {formatNumber(registration.payment_amount)} บาท
                </span>
              </Col>
            </Row>
            <Divider />
            <Row justify="space-between">
              <Col><strong>{t('remaining_balance')}:</strong></Col>
              <Col>
                <span style={{ color: 'red', fontWeight: 'bold' }}>
                  {formatNumber(registration.remaining_amount)} บาท
                </span>
              </Col>
            </Row>
          </Col>
        </Row>

        <Divider />

        {/* Signature Section */}
        <Row gutter={[16, 16]} justify="space-between" className="signature-section">
          <Col span={12}>
            {/* Display receiver's signature as an image if available */}
            {registration.signature ? (
              <>
                <img src={registration.signature} alt="Receiver Signature" style={{ width: '200px', height: '100px', marginBottom: '20px' }} />
                <p>{t('ผู้รับเงิน')}</p>
              </>
            ) : (
              <>

              </>
            )}
          </Col>
        </Row>

        {/* Print Button */}
        <div className="receipt-actions text-right">
          <Button type="primary" onClick={handlePrint}>{t('printReceipt')}</Button>
        </div>
      </div>
    </Modal>
  );
};

export default ReceiptModal;
