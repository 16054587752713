import React, { useState, useEffect } from 'react';
import { Form, Select, Input, Button, notification, Table, Modal } from 'antd';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import withRoleAccess from '../../hoc/withRoleAccess';

const { Option } = Select;

const CoursePage = () => {
  const { t, i18n } = useTranslation();
  const [subjects, setSubjects] = useState([]);
  const [courseCode, setCourseCode] = useState('');
  const [courseName, setCourseName] = useState('');
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [price, setPrice] = useState('');
  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  
  const API_URL = process.env.REACT_APP_API_URL;

  // Fetch subjects
  useEffect(() => {
    const fetchSubjects = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${API_URL}/subjects`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setSubjects(response.data);
      } catch (error) {
        notification.error({ message: 'Error fetching subjects' });
      }
    };
    fetchSubjects();
  }, [API_URL]);

  // Fetch courses
  const fetchCourses = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${API_URL}/classes/courses`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setCourses(response.data);
    } catch (error) {
      notification.error({ message: 'Error fetching courses' });
    }
  };

  useEffect(() => {
    fetchCourses();
  }, [API_URL]);

  // Handle course creation
  const handleSubmit = async () => {
    const token = localStorage.getItem('token');
    if (!courseCode || !courseName || selectedSubjects.length === 0 || !price) {
      notification.warning({ message: 'Please complete all fields before submitting' });
      return;
    }
    try {
      setLoading(true);
      await axios.post(`${API_URL}/classes/courses/create`, 
        {
          course_code: courseCode,
          course_name: courseName,
          subjects: selectedSubjects,
          price
        }, 
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      notification.success({ message: 'Course created successfully' });
      setCourseCode('');
      setCourseName('');
      setSelectedSubjects([]);
      setPrice('');
      setIsModalVisible(false);
      fetchCourses(); // Refresh the course list after creation
    } catch (error) {
      notification.error({ message: 'Error creating course' });
    } finally {
      setLoading(false);
    }
  };

  // Table columns
  const columns = [
    {
      title: t('classcode'),
      dataIndex: 'course_code',
      key: 'course_code',
    },
    {
      title: t('courseDetails'),
      dataIndex: 'course_name',
      key: 'course_name',
    },
    {
      title: t('price'),
      dataIndex: 'price',
      key: 'price',
      render: (price) => new Intl.NumberFormat('en-US', { 
          style: 'decimal', 
          minimumFractionDigits: 2, 
          maximumFractionDigits: 2 
        }).format(price),
    },    
    {
      title: t('subjects'),
      dataIndex: 'subjects',
      key: 'subjects',
      render: (subjects) => {
        // Check if subjects is a valid array and not an empty string
        if (Array.isArray(subjects) && subjects.length > 0) {
          return subjects.join(', '); // Join the subjects array with commas
        }
        return 'No subjects'; // Fallback text if there are no subjects
      },
    },
    
  ];

  return (
    <div>      
      {/* Button to open modal */}
      <Button type="primary" onClick={() => setIsModalVisible(true)}>
      {t('createCourse')}
      </Button>

      {/* Modal for creating a course */}
      <Modal
        title={t('createCourse')}
        visible={isModalVisible}
        onOk={handleSubmit}
        onCancel={() => setIsModalVisible(false)}
        confirmLoading={loading}
      >
        <Form layout="vertical">
          <Form.Item label={t('classcode')}>
            <Input
              value={courseCode}
              onChange={e => setCourseCode(e.target.value)}
              placeholder={t('Entercoursecode')}
            />
          </Form.Item>

          <Form.Item label={t('courseDetails')}>
            <Input
              value={courseName}
              onChange={e => setCourseName(e.target.value)}
              placeholder={t('Entercoursename')}
            />
          </Form.Item>

          <Form.Item label={t('subjects')}>
            <Select
              placeholder={t('selectSubject')}
              onChange={value => setSelectedSubjects([value])}  // Store a single selected subject in an array
              value={selectedSubjects[0]}  // Display the selected subject
            >
              {subjects.map(subject => (
                <Option key={subject.subjectid} value={subject.subjectid}>
                  {`${subject.subjectgroup} - ${subject.title}`}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label={t('price')}>
            <Input
              type="number"
              value={price}
              onChange={e => setPrice(e.target.value)}
              placeholder={t('Enterprice')}
            />
          </Form.Item>
        </Form>
      </Modal>

      {/* Course List Table */}
      <Table
        dataSource={courses}
        columns={columns}
        rowKey="id"
        style={{ marginTop: '20px' }}
        scroll={{ x: true }}
          pagination={{
            pageSize: 50,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          }}
      />
    </div>
  );
};

export default withRoleAccess(CoursePage, ['superadmin']);
