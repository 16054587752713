import React, { useState, useEffect } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faUser, faUserGraduate, faExclamationCircle, faFileExcel, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import PasswordModal from './PasswordModal';
import EditStudentModal from './EditStudentModal';
import CreateStudentFastForm from '../CreateUserform/CreateStudentFastForm';
import '../Dashboard.css';
import moment from 'moment';
import { Modal, Button } from 'antd';
import { refreshToken, isTokenExpiredError } from '../../Utils/authUtils';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as XLSX from 'xlsx';

function EditDeleteStudent({ onCreateNewStudent }) {
    const { t } = useTranslation();
    const [students, setStudents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isFastFormVisible, setIsFastFormVisible] = useState(false);
    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [studentIdToDelete, setStudentIdToDelete] = useState(null);
    const [studentIdToEdit, setStudentIdToEdit] = useState(null);
    const [passwordError, setPasswordError] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [filteredStudents, setFilteredStudents] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [editBlacklist, setEditBlacklist] = useState(null);
    const [selectedBlacklist, setSelectedBlacklist] = useState('');
    const [selectedBlacklistReason, setSelectedBlacklistReason] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const navigate = useNavigate();
    const API_URL = process.env.REACT_APP_API_URL;

    const ITEMS_PER_PAGE = 50;

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Intl.DateTimeFormat(t('locale'), options).format(date).toUpperCase();
    };

    useEffect(() => {
        const fetchStudents = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${API_URL}/students`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setStudents(response.data);
                setFilteredStudents(response.data);
                setTotalPages(Math.ceil(response.data.length / ITEMS_PER_PAGE));
                setLoading(false);
            } catch (error) {
                console.error('Error fetching students:', error);
                if (isTokenExpiredError(error)) {
                    refreshToken().then(() => {
                        fetchStudents();
                    }).catch(err => {
                        console.error('Token refresh failed:', err);
                        setError(t('sessionExpired'));
                    });
                } else {
                    setError(t('fetchStudentsError'));
                    setLoading(false);
                }
            }
        };

        fetchStudents();
    }, [API_URL, t]);

    const handleSearchChange = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchTerm(value);

        const filtered = students.filter(student =>
            student.firstnamethai.toLowerCase().includes(value) ||
            student.lastnamethai.toLowerCase().includes(value) ||
            student.nationalid.toLowerCase().includes(value)
        );

        setFilteredStudents(filtered);
        setCurrentPage(1);
        setTotalPages(Math.ceil(filtered.length / ITEMS_PER_PAGE));
    };

    const handleDateFilter = () => {
        const filtered = students.filter(student => {
            const studentDate = moment(student.createdat);
            const isAfterStartDate = startDate ? studentDate.isSameOrAfter(moment(startDate), 'day') : true;
            const isBeforeEndDate = endDate ? studentDate.isSameOrBefore(moment(endDate), 'day') : true;
            return isAfterStartDate && isBeforeEndDate;
        });
        setFilteredStudents(filtered);
        setCurrentPage(1);
        setTotalPages(Math.ceil(filtered.length / ITEMS_PER_PAGE));
    };

    const handleExportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(filteredStudents);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Students');
        XLSX.writeFile(workbook, 'Students.xlsx');
    };

    const sortedStudents = [...filteredStudents].sort((b, a) => a.schoolid.localeCompare(b.schoolid));
    const currentStudents = sortedStudents.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);

    const goToPreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const goToNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    const goToPage = (number) => {
        setCurrentPage(number);
    };

    const handleBlacklistUpdate = async (studentId) => {
        try {
            const token = localStorage.getItem('token');
            const data = {
                blacklist: selectedBlacklist === 'blacklist',
                blacklistreason: selectedBlacklistReason,
            };
            await axios.put(`${API_URL}/students/${studentId}/blacklist`, data, {
                headers: { Authorization: `Bearer ${token}` }
            });

            setStudents(prev => prev.map(student =>
                student.studentid === studentId ? { ...student, ...data } : student
            ));

            setEditBlacklist(null); // Close the edit state after saving

            confirmAlert({
                title: t('success'),
                message: t('blacklistUpdated'),
                buttons: [{ label: 'OK', onClick: () => {} }]
            });
        } catch (error) {
            console.error('Error updating blacklist:', error);
            setError(t('updateBlacklistError'));
        }
    };


    const handleEdit = (studentId) => {
        setStudentIdToEdit(studentId);
        setIsEditModalOpen(true);
    };

    const handleDelete = (studentId) => {
        setStudentIdToDelete(studentId);
        setPasswordError('');
        setIsPasswordModalOpen(true);
    };

    const confirmDelete = async (password) => {
        setIsPasswordModalOpen(false);
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                setError(t('noTokenFound'));
                return;
            }
            await axios.delete(`${API_URL}/students/${studentIdToDelete}`, {
                headers: { Authorization: `Bearer ${token}` },
                data: { password }
            });
            setStudents(prev => prev.filter(student => student.studentid !== studentIdToDelete));
            
            confirmAlert({
                title: t('success'),
                message: t('studentDeleted'),
                buttons: [{ label: 'OK', onClick: () => {} }]
            });
        } catch (error) {
            console.error('Error deleting student:', error);
            setError(t('deleteStudentError'));
        }
    };

    const saveStudent = async (updatedStudent) => {
        try {
            const token = localStorage.getItem('token');
            await axios.put(`${API_URL}/students/${updatedStudent.studentid}`, updatedStudent, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setStudents(prev => prev.map(student => student.studentid === updatedStudent.studentid ? updatedStudent : student));
            setIsEditModalOpen(false);

            confirmAlert({
                title: t('success'),
                message: t('studentUpdated'),
                buttons: [{ label: 'OK', onClick: () => {} }]
            });
        } catch (error) {
            console.error('Error updating student:', error);
            setError(t('updateStudentError'));
        }
    };

    const viewStudentDashboard = (studentId) => {
        navigate(`/student-dashboard-admin/${studentId}`);
    };

    function calculateAge(dateOfBirth) {
        if (!dateOfBirth) return ''; // Return empty if no dateOfBirth is provided
        const birthDate = moment(dateOfBirth, 'YYYY-MM-DD'); // Assuming dateOfBirth is in 'YYYY-MM-DD' format
        const age = moment().diff(birthDate, 'years'); // Calculate age in years
        return age;
      }

      const showFastForm = () => {
        setIsFastFormVisible(true);
    };

    const hideFastForm = () => {
        setIsFastFormVisible(false);
    };

    if (loading) return <p>{t('loading')}</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="adminstudent-container">
            <div className="admincontainer">
            <div className="button-group">
                <Button 
                    type="primary" 
                    onClick={onCreateNewStudent} 
                    icon={<FontAwesomeIcon icon={faUserGraduate} />}
                >
                    {t('createNewStudent')}
                </Button>
                <Button 
                    onClick={showFastForm} 
                    icon={<FontAwesomeIcon icon={faUserPlus} />}
                >
                    {t('createStudentFast')}
                </Button>
                </div>
                <div className="ant-filter-container">
                    <div className="ant-filter-row">
                        <span className="ant-filter-label">{t('filterByDate')}:</span>
                        <DatePicker
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                        placeholderText={t('startDate')}
                        dateFormat="dd/MM/yyyy"
                        className="ant-datepicker"
                        />
                        <DatePicker
                        selected={endDate}
                        onChange={date => setEndDate(date)}
                        placeholderText={t('endDate')}
                        dateFormat="dd/MM/yyyy"
                        className="ant-datepicker"
                        />
                        <button onClick={handleDateFilter} className="ant-btn ant-btn-primary">
                        {t('applyFilters')}
                        </button> 
                        
                        <button onClick={handleExportToExcel} className="ant-btn ant-btn-default">
                        <FontAwesomeIcon icon={faFileExcel} /> {t('exportToExcel')}
                        </button>
                    </div>
      
                        <div className="ant-filter-row">
                            <input
                            type="text"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            placeholder={t('searchByNameOrNationalID')}
                            className="ant-input ant-input-search"
                            />
                        </div>
                        </div>
                <div className="pagination-container">
                    <button onClick={goToPreviousPage} disabled={currentPage === 1} className="page-button">
                        {t('previous')}
                    </button>
                    {[...Array(totalPages).keys()].map(number => (
                        <button key={number + 1} onClick={() => goToPage(number + 1)} className={`page-button ${currentPage === number + 1 ? 'active' : ''}`}>
                            {number + 1}
                        </button>
                    ))}
                    <button onClick={goToNextPage} disabled={currentPage === totalPages} className="page-button">
                        {t('next')}
                    </button>
                </div>
                
                <div className="task-list-container">
                    <table className="task-list-table">
                        <thead>
                            <tr>
                                <th>{t('dashboard')}</th>
                                <th>{t('schoolid')}</th>
                                <th>{t('applydate')}</th>
                                <th>{t('name')}</th>
                                <th>{t('nickname')}</th>
                                <th>{t('nationalid')}</th>
                                <th>{t('dob')}</th>
                                <th>{t('age')}</th>
                                <th>{t('phone')}</th>
                                <th>{t('branch')}</th>
                                <th>{t('applicationFee')}</th>
                                <th>{t('createby')}</th>
                                <th>{t('blacklist')}</th>
                                <th>{t('actions')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentStudents.map((student) => (
                                <tr key={student.studentid} className={student.blacklist ? 'blacklist-row' : ''}>
                                    <td>
                                        <button onClick={() => viewStudentDashboard(student.studentid)} className="dashboard-button">
                                            <FontAwesomeIcon icon={faUser} />
                                        </button>
                                    </td>
                                    <td>{student.schoolid}</td>
                                    <td>{formatDate(student.createdat)}</td>
                                    <td>{student.prefix ? t(student.prefix.toLowerCase()) : ''} {student.firstnamethai} {student.lastnamethai}</td>
                                    <td>{student.nickname}</td>
                                    <td>{student.nationalid}</td>
                                    <td>{formatDate(student.date_of_birth)}</td>
                                    <td>{calculateAge(student.date_of_birth)} {t('year')}</td>
                                    <td>{student.phone}</td>
                                    <td>{student.branch}</td>
                                    <td>{student.application_fee} {t('baht')}</td>
                                    <td>admin {student.createby}</td>
                                    <td style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                                    {student.blacklistreason ? t(student.blacklistreason) : t('No Record')}
                                    </td>
                                    <td>
                                        <div className="action-buttons">
                                            <button onClick={() => handleEdit(student.studentid)} className="edit-button">
                                                <FontAwesomeIcon icon={faEdit} />
                                            </button>
                                            <button onClick={() => handleDelete(student.studentid)} className="delete-button">
                                                <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                            {editBlacklist === student.studentid ? (
                                                <>
                                                    <select
                                                        value={selectedBlacklist}
                                                        onChange={(e) => setSelectedBlacklist(e.target.value)}
                                                    >
                                                        <option value="">{t('Select option')}</option>
                                                        <option value="norecord">{t('No Record')}</option>
                                                        <option value="blacklist">{t('Blacklist')}</option>
                                                        <option value="notPassed">{t('Not Passed')}</option>
                                                    </select>
                                                    {selectedBlacklist && (
                                                        <select
                                                            value={selectedBlacklistReason}
                                                            onChange={(e) => setSelectedBlacklistReason(e.target.value)}
                                                        >
                                                            <option value="">{t('Select reason')}</option>
                                                            {selectedBlacklist === 'blacklist' ? (
                                                                <>
                                                                    <option value="fighting">{t('Involved in fighting')}</option>
                                                                    <option value="drugUse">{t('Drug use or gambling')}</option>
                                                                    <option value="theft">{t('Theft')}</option>
                                                                    <option value="examFraud">{t('Exam fraud')}</option>
                                                                    <option value="longAbsence">{t('Absence for over a month')}</option>
                                                                    <option value="drop3month">{t('Drop for over 3 month')}</option>
                                                                    <option value="copyrightViolation">{t('Copyright violation')}</option>
                                                                    <option value="others">{t('Others (please specify)')}</option>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <option value="sickLeave">{t('On sick leave')}</option>
                                                                    <option value="failedAssessment">{t('Failed assessment')}</option>
                                                                </>
                                                            )}
                                                        </select>
                                                    )}
                                                    <button onClick={() => handleBlacklistUpdate(student.studentid)} className="save-blacklist-button">
                                                        {t('Save')}
                                                    </button>
                                                </>
                                            ) : (
                                                <button onClick={() => { setEditBlacklist(student.studentid); setSelectedBlacklist(''); setSelectedBlacklistReason(''); }} className="blacklist-button">
                                                    <FontAwesomeIcon icon={faExclamationCircle} />
                                                </button>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <PasswordModal
                isOpen={isPasswordModalOpen}
                onClose={() => setIsPasswordModalOpen(false)}
                onConfirm={confirmDelete}
                errorMessage={passwordError}
            />
            <EditStudentModal
                isOpen={isEditModalOpen}
                onClose={() => setIsEditModalOpen(false)}
                onSave={saveStudent}
                studentId={studentIdToEdit}
            />
             <Modal
                title={t('createStudentFast')}
                visible={isFastFormVisible}
                onCancel={hideFastForm}
                footer={null}
                width={800}
            >
                <CreateStudentFastForm onClose={hideFastForm} />
            </Modal>
        </div>
    );
}

export default withRoleAccess(EditDeleteStudent, ['admin', 'superadmin']);
