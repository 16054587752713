import React, { useState } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { useTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import th from 'date-fns/locale/th'; // Import the Thai locale from date-fns
import moment from 'moment';
import 'moment/locale/th'; // Import Thai locale for moment
import 'react-datepicker/dist/react-datepicker.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './CreateUserForm.css';

moment.locale('th'); 
registerLocale('th', th); 
setDefaultLocale('th');

const initialFormData = {
  prefix: '',
  firstnamethai: '',
  lastnamethai: '',
  firstnameenglish: '',
  lastnameenglish: '',
  nickname: '',
  nationalid: '',
  phone: '',
  address: '',
  email: '',
  date_of_birth: '',
  height: '',
  weight: '',
  race: '',
  religion: '',
  nationality: '',
  emergency_phone_number: '',
  education: '', // Dropdown options will be handled here
  massage_education: '',
  medicalhistory: '',
  application_fee: '',
  photourl: '',
  createby: '', // Assuming you fill this with the current user info
  updateby: '', // Same here
  status: 'active', // Default status
  branch: '', // Select between 'Bangkok' and 'Pattaya'
  blacklist: false, // Default to false
  blacklistreason: '',
};

function CreateStudentForm() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(initialFormData);
  const [error, setError] = useState(null);
  const [duplicateCheckMessage, setDuplicateCheckMessage] = useState('');
  const [duplicateCheckColor, setDuplicateCheckColor] = useState('');
  const [age, setAge] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  const formatThaiDate = (date) => {
    if (!date) return '';
    const thaiYear = moment(date).year() + 543;
    return moment(date).format(`DD/MM/${thaiYear}`);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleDateChange = (date) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    setFormData({ ...formData, date_of_birth: formattedDate });
    setAge(calculateAge(formattedDate)); // Update age when date of birth changes
  };

  const calculateAge = (birthdate) => {
    const today = moment();
    const birthDate = moment(birthdate, 'YYYY-MM-DD');
    return today.diff(birthDate, 'years');
  };

  const checkForDuplicates = async () => {
    try {
      const token = localStorage.getItem('token');
      const { nationalid } = formData;
      const response = await axios.get(`${API_URL}/students/check-duplicates`, {
        params: { nationalid },
        headers: { Authorization: `Bearer ${token}` }
      });

      if (response.data.exists) {
        setDuplicateCheckMessage(t('duplicatenationalid'));
        setDuplicateCheckColor('red');
      } else {
        setDuplicateCheckMessage(t('noduplicatenationalid'));
        setDuplicateCheckColor('green');
      }
    } catch (error) {
      console.error('Error checking for duplicates:', error);
      setError(t('errorCheckingDuplicates'));
    }
  };
  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem('token');

      const dataToSend = {
        ...formData,
      };

      // Log the data you're sending to the API
      console.log('Data to send:', dataToSend);

      const response = await axios.post(`${API_URL}/students`, dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });

      // Log the API response
      console.log('API Response:', response.data);

      setFormData(initialFormData);
      setError(null);
    } catch (error) {
      // Log the full error object for detailed debugging
      console.error('Error creating student:', error.response ? error.response.data : error.message);
      
      setError(error.response ? error.response.data.message : error.message);
      
      // Optionally, log the error details if there's a response
      if (error.response) {
        console.log('Error Response:', error.response);
      } else {
        console.log('Error Message:', error.message);
      }
    }
};


  const showConfirmation = () => {
    confirmAlert({
      title: t('confirm_title'),
      message: (
        <div>
          <p>{t('confirm_message')}</p>
          <ul>
            <li>{t('first_name_thai')}: {formData.firstnamethai}</li>
            <li>{t('last_name_thai')}: {formData.lastnamethai}</li>
            <li>{t('first_name_english')}: {formData.firstnameenglish}</li>
            <li>{t('last_name_english')}: {formData.lastnameenglish}</li>
            <li>{t('nickname')}: {formData.nickname}</li>
            <li>{t('national_id')}: {formData.nationalid}</li>
            <li>{t('phone')}: {formData.phone}</li>
            <li>{t('address')}: {formData.address}</li>
            <li>{t('email')}: {formData.email}</li>
            <li>{t('date_of_birth')}: {formData.date_of_birth}</li>
            <li>{t('height')}: {formData.height}</li>
            <li>{t('weight')}: {formData.weight}</li>
            <li>{t('race')}: {formData.race}</li>
            <li>{t('religion')}: {formData.religion}</li>
            <li>{t('nationality')}: {formData.nationality}</li>
            <li>{t('emergency_phone_number')}: {formData.emergency_phone_number}</li>
            <li>{t('education')}: {formData.education}</li>
            <li>{t('branch')}: {formData.branch}</li>
            <li>{t('massage_education')}: {formData.massage_education}</li>
            <li>{t('medical_history')}: {formData.medicalhistory}</li>
            <li>{t('application_fee')}: {formData.application_fee}</li>
          </ul>
        </div>
      ),
      buttons: [
        {
          label: t('confirm'),
          onClick: handleSubmit
        },
        {
          label: t('cancel'),
          onClick: () => {}
        }
      ]
    });
  };

  return (
    <div className="form-page">
      <Sidebar />
      <Header />
      <div className="form-content">
      <h2>{t('add_new_student')}</h2>
        <form onSubmit={(e) => { e.preventDefault(); showConfirmation(); }} className="create-user-form">
          <div className="section">
            <h3>{t('student_details')}</h3>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="prefix">{t('name_prefix')}</label>
                <select name="prefix" value={formData.prefix} onChange={handleChange} required>
                    <option value="">{t('select_prefix')}</option>
                    <option value="Miss">{t('miss')}</option>
                    <option value="Mrs">{t('mrs')}</option>
                    <option value="Mr">{t('mr')}</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="firstnamethai">{t('first_name_thai')}</label>
                <input
                  type="text"
                  name="firstnamethai"
                  value={formData.firstnamethai}
                  onChange={handleChange}
                  placeholder={t('first_name_thai')}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="lastnamethai">{t('last_name_thai')}</label>
                <input
                  type="text"
                  name="lastnamethai"
                  value={formData.lastnamethai}
                  onChange={handleChange}
                  placeholder={t('last_name_thai')}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="firstnameenglish">{t('first_name_english')}</label>
                <input
                  type="text"
                  name="firstnameenglish"
                  value={formData.firstnameenglish}
                  onChange={handleChange}
                  placeholder={t('first_name_english')}
                />
              </div>
              <div className="form-group">
                <label htmlFor="lastnameenglish">{t('last_name_english')}</label>
                <input
                  type="text"
                  name="lastnameenglish"
                  value={formData.lastnameenglish}
                  onChange={handleChange}
                  placeholder={t('last_name_english')}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="nickname">{t('nickname')}</label>
                <input
                  type="text"
                  name="nickname"
                  value={formData.nickname}
                  onChange={handleChange}
                  placeholder={t('nickname')}
                />
              </div>
              <div className="form-group">
                <label htmlFor="nationalid">{t('national_id')}</label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    type="text"
                    name="nationalid"
                    value={formData.nationalid}
                    onChange={handleChange}
                    placeholder={t('national_id')}
                    style={{ marginRight: '8px' }}
                  />
                  <button type="button" onClick={checkForDuplicates} className="duplicate-button">
                    {t('check')}
                  </button>
                </div>
                {duplicateCheckMessage && (
                  <p style={{ color: duplicateCheckColor }}>{duplicateCheckMessage}</p>
                )}
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
              <label htmlFor="date_of_birth">{t('dob')}</label>
               <DatePicker
              selected={formData.date_of_birth ? moment(formData.date_of_birth, 'YYYY-MM-DD').toDate() : null}
              onChange={handleDateChange}
              dateFormat="dd/MM/yyyy"
              locale="th"
              calendarStartDay={1}
              showYearDropdown
              yearDropdownItemNumber={100}
              scrollableYearDropdown
              customInput={
                <input
                  type="text"
                  name="date_of_birth"
                  placeholder={t('dob_placeholder')}
                  value={formData.date_of_birth ? formatThaiDate(formData.date_of_birth) : ''}
                  readOnly
                />
              }
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <div style={{ margin: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <button
                    className="date-picker-nav-button"
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                  >
                    {"<"}
                  </button>
                  <select
                    value={moment(date).month()}
                    onChange={({ target: { value } }) => changeMonth(value)}
                    className="date-picker-select"
                  >
                    {moment.months().map((month, index) => (
                      <option key={month} value={index}>
                        {month}
                      </option>
                    ))}
                  </select>
                  <select
                    value={moment(date).year()}
                    onChange={({ target: { value } }) => changeYear(value)}
                    className="date-picker-select"
                  >
                    {Array.from({ length: 100 }, (_, i) => moment(date).year() - 50 + i).map((year) => (
                      <option key={year} value={year}>
                        {year + 543}
                      </option>
                    ))}
                  </select>
                  <button
                    className="date-picker-nav-button"
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                  >
                    {">"}
                  </button>
                </div>
              )}
            />
        </div>
              <div className="form-group">
          <label htmlFor="age">{t('age')}</label>
          <input
            type="text"
            name="age"
            value={age !== null ? age : ''}
            placeholder={t('age')}
            readOnly
          />
        </div>
              <div className="form-group">
                <label htmlFor="phone">{t('phone')}</label>
                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder={t('phone')}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="height">{t('height')}</label>
                <input
                  type="number"
                  name="height"
                  value={formData.height}
                  onChange={handleChange}
                  placeholder={t('height')}
                />
              </div>
              <div className="form-group">
                <label htmlFor="weight">{t('weight')}</label>
                <input
                  type="number"
                  name="weight"
                  value={formData.weight}
                  onChange={handleChange}
                  placeholder={t('weight')}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="race">{t('race')}</label>
                <input
                  type="text"
                  name="race"
                  value={formData.race}
                  onChange={handleChange}
                  placeholder={t('race')}
                />
              </div>
              <div className="form-group">
                <label htmlFor="religion">{t('religion')}</label>
                <select name="religion" value={formData.religion} onChange={handleChange}>
                  <option value="">{t('select_religion')}</option>
                  <option value="Buddhist">{t('Buddhist')}</option>
                  <option value="Christian">{t('Christian')}</option>
                  <option value="Catholic">{t('Catholic')}</option>
                  <option value="Islam">{t('Islam')}</option>
                  <option value="Others">{t('Others')}</option>
                </select>
                {formData.religion === 'Others' && (
                  <input
                    type="text"
                    name="otherReligion"
                    value={formData.otherReligion}
                    onChange={handleChange}
                    placeholder={t('specify_other_religion')}
                  />
                )}
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="nationality">{t('nationality')}</label>
                <input
                  type="text"
                  name="nationality"
                  value={formData.nationality}
                  onChange={handleChange}
                  placeholder={t('nationality')}
                />
              </div>
              <div className="form-group">
                <label htmlFor="emergency_phone_number">{t('emergency_phone_number')}</label>
                <input
                  type="text"
                  name="emergency_phone_number"
                  value={formData.emergency_phone_number}
                  onChange={handleChange}
                  placeholder={t('emergency_phone_number')}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="email">{t('email')}</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder={t('email')}
              />
            </div>
            <div className="form-group">
              <label htmlFor="address">{t('address')}</label>
              <textarea
                name="address"
                value={formData.address}
                onChange={handleChange}
                placeholder={t('address')}
              />
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="education">{t('education')}</label>
                <select name="education" value={formData.education} onChange={handleChange}>
                  <option value="">{t('select_education')}</option>
                  <option value="below_highschool">{t('below_highschool')}</option>
                  <option value="highschool">{t('highschool')}</option>
                  <option value="bachelor_degree">{t('bachelor_degree')}</option>
                  <option value="above_bachelor_degree">{t('above_bachelor_degree')}</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="branch">{t('branch')}</label>
                <select name="branch" value={formData.branch} onChange={handleChange} >
                  <option value="">{t('select_branch')}</option>
                  <option value="Bangkok">{t('bangkok')}</option>
                  <option value="Pattaya">{t('pattaya')}</option>
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="massage_education">{t('massage_education')}</label>
                <textarea
                  name="massage_education"
                  value={formData.massage_education}
                  onChange={handleChange}
                  placeholder={t('massage_education')}
                />
              </div>
              <div className="form-group">
                <label htmlFor="medicalhistory">{t('medical_history')}</label>
                <textarea
                  name="medicalhistory"
                  value={formData.medicalhistory}
                  onChange={handleChange}
                  placeholder={t('medical_history')}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="application_fee">{t('application_fee')}</label>
              <input
                type="text"
                name="application_fee"
                value={formData.application_fee}
                onChange={handleChange}
                placeholder={t('application_fee')}
              />
            </div>
          </div>
          <button type="submit" className="submit-button">{t('create_student')}</button>
          {error && <div className="error-message">{t('error_message')}</div>}
        </form>
      </div>
    </div>
  );
  
}

export default withRoleAccess(CreateStudentForm, ['admin', 'superadmin']);
